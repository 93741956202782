import { useEffect, useState } from 'react'
import { useAuthUser } from '../hooks/AuthUserContext'
import { useGetWorkersOfShop } from '../queries/WorkersQuery'
import { Worker } from '../types/WorkerListResponse'
import { useParams } from 'react-router-dom'
import Loading from '../components/Loading'
import ShopHeader from '../components/ShopHeader'
import WorkerListTable from '../components/WorkerListTable'
import WorkerCurriculumBulkAddRemove from '../components/WorkerCurriculumBulkAddRemove'


function WorkerList() {
  const { shopId, brandId } = useParams()
  const { authUser, setAuthUser } = useAuthUser()
  const [isBulkEditing, setIsBulkEditing] = useState<boolean>(false)
  const [curriculumBrandName, setCurriculumBrandName] = useState<string|undefined>('')
  const [employmentTypeId, setEmploymentTypeId] = useState<string|undefined>('')
  const [showSupports, setShowSupports] = useState<boolean|undefined>(false)
  const [showAlarm, setShowAlarm] = useState<boolean|undefined>(false)
  const [changeStaffListSortState, setChangeStaffListSortState] = useState<string>('default');

  const {
    isLoading,
    isFetching,
    refetch,
    data: workerListResponse,
  } = useGetWorkersOfShop({ shopId }, { brandId }, { curriculumBrandName }, { employmentTypeId })

  const [workersSorted, setWorkersSorted] = useState<Worker[]>()

  const onUpdate = () => {
    refetch()
  }

  const toggleBulkEditing = () => {
    let isEditing = !isBulkEditing
    setIsBulkEditing(isEditing)
    if (isEditing) {
      setCurriculumBrandName(workerListResponse?.brand.id)
    } else {
      setCurriculumBrandName('')
    }
  }

  const setHasSupporter = (supporter: boolean) => {
    setShowSupports(supporter)
  }

  const setHasShowAlarm = (showAlarm: boolean) => {
    setShowAlarm(showAlarm);
  }

  const setChangeStaffListSort = (sort: string) => {
    setChangeStaffListSortState(sort)
  }

  useEffect(() => {
    applyFilters();
  }, [showAlarm, showSupports, changeStaffListSortState]);


  // consts
  const sortWorkersByDoneTasks = (workers: Worker[]) => {
    return workers.sort((a, b) => {
      const doneNumA = a.curriculums.reduce(
        (carry, curriculum) =>
          carry +
          curriculum.steps.reduce(
            (stepCarry, step) => stepCarry + step.doneTasks,
            0
          ),
        0
      );
      const doneNumB = b.curriculums.reduce(
        (carry, curriculum) =>
          carry +
          curriculum.steps.reduce(
            (stepCarry, step) => stepCarry + step.doneTasks,
            0
          ),
        0
      );

      return doneNumB - doneNumA;
    });
  };

  function sortWorkers(workers: Worker[], sort?: string) {

    return workers.sort((workerA, workerB) => {
      const curriculumsA = workerA.curriculums;
      const curriculumsB = workerB.curriculums;

      const crownA = curriculumsA.filter((curriculum) => curriculum.doneSteps === curriculum.totalSteps);
      const pendingStepTasksACount = curriculumsA.filter((curriculum) => curriculum.doneSteps !== curriculum.totalSteps).length;
      const crownB = curriculumsB.filter((curriculum) => curriculum.doneSteps === curriculum.totalSteps);
      const pendingStepTasksBCount = curriculumsB.filter((curriculum) => curriculum.doneSteps !== curriculum.totalSteps).length;
      const crownACount = crownA.length;
      const crownBCount = crownB.length;
      if (crownACount !== crownBCount) {
        if (sort === 'default') {
          return crownBCount - crownACount;
        }
        return sort === 'asc' ? crownBCount - crownACount : crownACount - crownBCount;
      }
      const usedCrownASteps = crownA.reduce((acc, curriculum) => acc + curriculum.doneSteps, 0);
      const usedCrownBSteps = crownB.reduce((acc, curriculum) => acc + curriculum.doneSteps, 0);

      const sumDoneStepsA = curriculumsA.reduce((acc, curriculum) => acc + curriculum.doneSteps - usedCrownASteps, 0);
      const sumDoneStepsB = curriculumsB.reduce((acc, curriculum) => acc + curriculum.doneSteps - usedCrownBSteps, 0);

      if (sumDoneStepsA !== sumDoneStepsB) {
        if(sort === 'default') {
          return sumDoneStepsB - sumDoneStepsA;
        }
        return sort === 'asc' ? sumDoneStepsB - sumDoneStepsA : sumDoneStepsA - sumDoneStepsB;
      }

      if (sort === 'default') {
        return pendingStepTasksBCount - pendingStepTasksACount;
      }
      return sort === 'asc' ? pendingStepTasksBCount - pendingStepTasksACount : pendingStepTasksACount - pendingStepTasksBCount;
    });
  }

  const applyFilters = () => {
    let filteredWorkers = [];
    let workers = workerListResponse?.workers ?? [];

    if (showSupports === true) {
      filteredWorkers = workers.filter(worker => worker.isSupporter === true);
    } else {
      filteredWorkers = workerListResponse?.workers ?? [];
    }

    let workersSupports = sortWorkersByDoneTasks(filteredWorkers.filter(worker => worker.isSupporter === true));
    let workersNoSupports = sortWorkersByDoneTasks(filteredWorkers.filter(worker => worker.isSupporter === false));

    if (showAlarm === true) {
      workersNoSupports = workersNoSupports.filter(worker => worker.alarmNum > 0);
      workersSupports = workersSupports.filter(worker => worker.alarmNum > 0);
    }

    workersNoSupports = workersNoSupports.sort((a: Worker, b: Worker) => Number(a.id) - Number(b.id));

    workersSupports = sortWorkers(workersSupports, changeStaffListSortState);

    let workersFiltered = workersNoSupports.concat(workersSupports);

    if (changeStaffListSortState !== 'default') {
      workersFiltered = sortWorkers(workersFiltered, changeStaffListSortState);
    }

    setWorkersSorted(workersFiltered);
  }
  // effects
  useEffect(() => {
    applyFilters();
  }, [workerListResponse])

  return (
    <>
      <link rel="stylesheet" href="/css/pages/worker_list.css" />
      <div>
        {workerListResponse?.shop ? (
          <ShopHeader
            shopId={workerListResponse.shop.id}
            shopName={workerListResponse.shop.name}
            brandName={workerListResponse.brand.name}
            urlForm={workerListResponse.urlForm}
            growthGoal={workerListResponse.shop.growthGoal}
            brandId={workerListResponse.brand.id}
            onUpdate={onUpdate}
            isBulkEditing={isBulkEditing}
            onToggleBulkEditing={() => toggleBulkEditing()}
            curriculumBrandName={curriculumBrandName}
            handleCurriculumBrandName={(name: string) => setCurriculumBrandName(name)}
            hasSupporter={showSupports}
            setHasSupporter={(supporter: boolean) => setHasSupporter(supporter)}
            hasAlarm={showAlarm}
            setHasAlarm={(alarm: boolean) => setHasShowAlarm(alarm)}
            changeStaffListSortState={changeStaffListSortState}
            setChangeStaffListSortState={(sort) => setChangeStaffListSort(sort)}
          />
        ) : (
          ''
        )}

        {isLoading || isFetching ? (
          <Loading />
        ) : (
          <div style={{
            padding: '0px 20px 20px 20px',
            backgroundColor: '#eee',
            width: 'auto',
          }}>
            {!isBulkEditing ? (
              <WorkerListTable
                workerListResponse={workerListResponse}
                authUser={authUser}
                showSupports={showSupports}
                showAlarm={showAlarm}
                changeStaffListSortState={changeStaffListSortState}
                workersSorted={workersSorted||[]}
              />
            ) : (
              <WorkerCurriculumBulkAddRemove
                workerListResponse={workerListResponse}
                onSuccess={onUpdate}
                showSupports={showSupports}
                workersSorted={workersSorted||[]}
              />
            )}
          </div>

        )}
      </div>
    </>
  )
}

export default WorkerList
