import React, { FormEvent, useEffect, useState } from 'react'
import { TaskInstance } from '../../types/TaskInstance'
import { useUpdatePracticeFeedbackTrainerComment } from '../../queries/TasksQuery'
import Loading from '../Loading'
import { StButtonTypeB, StButtonTypeBDisabled } from '../../style/styles'
import { UserRole } from '../../types/UserRole'
import { useAuthUser } from '../../hooks/AuthUserContext'

type PracticeFeedbackModalProps = {
  taskInstance: TaskInstance
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function PracticeFeedbackModal(props: PracticeFeedbackModalProps) {
  const [workerComment, setWorkerComment] = useState<string>(
    props.taskInstance.workerComment || ''
  )
  const [trainerComment, setTrainerComment] = useState<string>(
    props.taskInstance.trainerComment || ''
  )
  const updatePracticeFeedbackTrainerComment =
    useUpdatePracticeFeedbackTrainerComment()

  const handleSubmitEditAsTrainer = (e: FormEvent) => {
    e.preventDefault()
    updatePracticeFeedbackTrainerComment.mutate({
      workerId: props.taskInstance.worker.id,
      taskId: props.taskInstance.task.id,
      comment: trainerComment,
    })
  }

  
  const { authUser } = useAuthUser()
  const hasAll = authUser?.role === UserRole.Manager || authUser?.role === UserRole.Admin
  const hasStore = authUser?.role === UserRole.Trainer || authUser?.role === UserRole.Worker || authUser?.role === UserRole.Store1

  useEffect(() => {
    if (
      updatePracticeFeedbackTrainerComment.isSuccess
    ) {
      props.onSuccess()
    }
  }, [
    updatePracticeFeedbackTrainerComment.isSuccess,
  ])

  return (
    <>
      <link
        rel="stylesheet"
        href="/css/components/modal/practice_feedback_modal.css"
      />
      {updatePracticeFeedbackTrainerComment.isLoading ? <Loading /> : ''}
      <div className="modal">
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.closeModal}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          <h1> {props.taskInstance.task.name} </h1>
        </div>

        <div>
          <form onSubmit={(e) => e.preventDefault()}>
            <fieldset>
              <div style={{ marginTop: '30px' }}>
                コメントの入力（必須項目）
                <textarea
                  disabled={hasStore}
                  rows={5}
                  value={trainerComment}
                  onChange={(e) => setTrainerComment(e.target.value)}
                />
              </div>
              {hasAll && (
                <button
                  type="submit"
                  style={StButtonTypeB}
                  onClick={handleSubmitEditAsTrainer}
                >
                  合格
                </button>
              )}
              {hasStore && (
                <button
                  type="submit"
                  style={StButtonTypeBDisabled}
                  disabled={true}
                >
                  合格
                </button>
              )}
            </fieldset>
          </form>
        </div>
      </div>
    </>
  )
}

export default PracticeFeedbackModal
