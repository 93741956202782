import axios from "axios";
import { WorkerListResponse } from "../types/WorkerListResponse";
import { WorkerDetailResponse } from "../types/WorkerDetailResponse";
import { CurriculumEditInformationResponse } from "../types/CurriculumEditInformationResponse";

const getWorkersOfShop = async (
  shopId: string | undefined,
  brandId: string | undefined,
  curriculumBrandName: string | undefined,
  employmentTypeId: string | undefined
) => {
  if (!shopId) return;

  const { data } = await axios.get<WorkerListResponse>(
    // todo task #84 #77
    `/api/shops/${shopId}/workers/${brandId}?curriculum_brand_name=${curriculumBrandName}&employment_type_id=${employmentTypeId}`
  );
  return data;
};

const getWorkerDetail = async (workerId: string | undefined, curriculumBrandName: string | undefined) => {
  if (!workerId) return;

  const { data } = await axios.get<WorkerDetailResponse>(
    `/api/workers/${workerId}?curriculum_brand_name=${curriculumBrandName}`
  );
  return data;
};

const updateGrowthGoal = async ({
  workerId,
  growthGoal,
}: {
  workerId: string;
  growthGoal: string;
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`);

  const { data } = await axios.put<{}>(`/api/workers/${workerId}/growthGoal`, {
    growthGoal,
  });
  return data;
};

const updateWorkerCurriculums = async ({
  workerId,
  curriculumIds,
}: {
  workerId: string;
  curriculumIds: Array<string>;
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`);

  const { data } = await axios.put<{}>(`/api/workers/${workerId}/curriculums`, {
    curriculumIds,
  });
  return data;
};

const reloadRelationCurriculum = async ({
  curriculumId,
}: {
  curriculumId: string;
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`);

  const { data } = await axios.post<{}>(`/api/curriculum/update-worker-relation`, { curriculumId: curriculumId });

  return data;
};

const registerWorkerCurriculum = async ({ workerCurriculums, }: { workerCurriculums: any; }) => {
  await axios.get(`/api/sanctum/csrf-cookie`);

  const { data } = await axios.post<{}>(`/api/worker/bulk-register-curriculums`, { workerCurriculums: workerCurriculums });

  return data;
};

const deleteWorkerCurriculums = async ({ workerCurriculums, }: { workerCurriculums: any; }) => {
  await axios.get(`/api/sanctum/csrf-cookie`);

  const { data } = await axios.post<{}>(`/api/worker/bulk-delete-curriculums`, { workerCurriculums: workerCurriculums });

  return data;
};

const getCurriculumEditInformation = async (
  areaId: string | undefined,
  shopId: string | undefined,
  brandId: string | undefined,
  employmentTypeId: string | undefined
) => {
  let url = `/api/get-curriculum-edit-information?`
  if (areaId) {
    url += `area_id=${areaId}&`
  }
  if (shopId) {
    url += `shop_id=${shopId}&`
  }
  if(brandId) {
    url += `brand_id=${brandId}&`
  }
  if(employmentTypeId) {
    url += `employment_type_id=${employmentTypeId}`
  }

  const { data } = await axios.get<CurriculumEditInformationResponse>(url);

  return data;
};

export {
  getWorkersOfShop,
  getWorkerDetail,
  updateGrowthGoal,
  updateWorkerCurriculums,
  reloadRelationCurriculum,
  registerWorkerCurriculum,
  deleteWorkerCurriculums,
  getCurriculumEditInformation
};
