import React, { useEffect, useState } from 'react'
import Loading from '../components/Loading'
import { GetShopsResponse, Curriculum, Courses } from '../types/GetShopsResponse'
import { Brand, Shop, Area, EmploymentType } from '../types/CurriculumEditInformationResponse'
import axios from 'axios'
import { useGetCurriculumEditInformation } from '../queries/WorkersQuery'
import { useRegisterWorkerCurriculum, useDeleteWorkerCurriculum } from '../queries/WorkersQuery'
import { NavLink } from 'react-router-dom'
import { StButtonTypeC } from '../style/styles'
import { toast } from 'react-toastify'
import useConfig from '../hooks/GetConfig'
interface CheckboxOption {
  id: string;
  label: string;
}

enum Tab {
  Add,
  Remove,
}

type WorkerCurriculum = {
  [workerId: string]: string[];
};

function CurriculumEdit() {
  // states
  const [brandId, setBrandId] = useState<string | undefined>(undefined)
  const [brandIdTarget, setBrandIdTarget] = useState<string | undefined>(undefined)
  const [areaId, setAreaId] = useState<string | undefined>('')
  const [shopId, setShopId] = useState<string | undefined>('')
  const [employmentTypeId, setEmploymentTypeId] = useState<string | undefined>(undefined)
  const [courseId, setCourseId] = useState<string | undefined>(undefined)
  const [shops, setShops] = useState<Shop[]>()
  const [brands, setBrands] = useState<Brand[]>()
  const [areas, setAreas] = useState<Area[]>()
  const [employmentTypes, setEmploymentTypes] = useState<EmploymentType[]>()
  const [courses, setCourses] = useState<Courses[]>()
  const [curriculumIds, setCurriculumIds] = useState<string[]>([]);
  const [curriculums, setCurriculums] = useState<Curriculum[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
  const [checkboxOptions, setCheckboxOptions] = useState<CheckboxOption[]>([]);
  const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Add)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [hasNoWorkerOption, setHasNoWorkerOption] = useState<boolean>(false)

  // hooks
  const registerWorkerCurriculums = useRegisterWorkerCurriculum()
  const deleteWorkerCurriculums = useDeleteWorkerCurriculum()

  const config = useConfig();

  // consts
  const isRequestingWorkerCurriculums = registerWorkerCurriculums.isLoading || deleteWorkerCurriculums.isLoading;
  const noWorkerSelected = checkedOptions.length === 0
  const noCurriculumSelected = curriculumIds.length === 0 || curriculumIds[0].trim() === '' || registerWorkerCurriculums.isLoading
  const commonButtonStyle = { maxWidth: '110px', opacity: noWorkerSelected ? 0.5 : 1, cursor: noWorkerSelected ? 'default' : 'pointer' };
  const sendButtonStyle = { maxWidth: '110px', opacity: noCurriculumSelected ? 0.5 : 1, cursor: noCurriculumSelected ? 'default' : 'pointer' }
  const addButtonStyle = { ...sendButtonStyle, backgroundColor: '#009000' };
  const deleteButtonStyle = { ...sendButtonStyle, backgroundColor: '#ff0000' };
  const sendButtonTypeStyle = selectedTab === Tab.Add ? addButtonStyle : deleteButtonStyle;
  const selectAllButton = {
    maxWidth: '110px',
    backgroundColor: '#3A99DC',
    cursor: checkboxOptions.length === 0 ? 'default' : '',
    opacity: checkboxOptions.length === 0 ? 0.5 : 1
  } 

  // apis
  const {
    data: curriculumEditInformationResponse,
  } = useGetCurriculumEditInformation({ areaId }, { shopId }, { brandId }, { employmentTypeId })

  const fetchShops = (e: { target: { value: React.SetStateAction<string | undefined> } }) => {
    setBrandId('')
    setShopId('')
    setAreaId(e.target.value)
  }

  const fetchBrands = (e: { target: { value: React.SetStateAction<string | undefined> } }) => {
    setShopId(e.target.value)
  }

  // methods
  const handleChange = (id: string) => {
    const newCheckedOptions = [...checkedOptions];
    if (newCheckedOptions.includes(id)) {
      setCheckedOptions(newCheckedOptions.filter((o) => o !== id));
    } else {
      setCheckedOptions([...newCheckedOptions, id]);
    }
  };

  const handleGetWorkers = async () => {
    const getWorkers = async () => {
      setIsLoading(true);
      if (curriculumEditInformationResponse?.workers) {
        const newCheckboxOptions = curriculumEditInformationResponse.workers.map((worker) => {
          return {
            id: worker.id,
            label: worker.name
          }
        });
        setCheckboxOptions([...newCheckboxOptions]);
        const newCheckedOptions = newCheckboxOptions.map((option) => {
          return option.id
        })
        setCheckedOptions([...newCheckedOptions])
        setIsChecked(true)
        if(newCheckboxOptions.length === 0) {
          setHasNoWorkerOption(true)
        } else {
          setHasNoWorkerOption(false)
        }
      }
    }
    getWorkers()
    setIsLoading(false);
  }

  const selectAllWorkers = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (checkboxOptions.length === 0) return
    e.preventDefault()
    setIsChecked(true)
    const newCheckedOptions = checkboxOptions.map((option) => {
      return option.id
    })
    setCheckedOptions([...newCheckedOptions])
  }

  const deselectAllWorkers = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsChecked(false)
    setCheckedOptions([])
  }

  const handleSetWorkers = async () => {
    setIsFormOpen(true)
  }

  const generateDeleteArrayForm = (workers: WorkerCurriculum) => {
    const workerCurriculumArr: any = [];
    for (const workerId in workers) {
      const curriculumArr = workers[workerId];
      curriculumArr.forEach(curriculumId => {
        workerCurriculumArr.push({ workerId, curriculumId });
      });
    }
    return workerCurriculumArr;
  }

  const createWorkerCurriculumObjectFromCheckedOptions = () => {
    let workers: WorkerCurriculum = checkedOptions.reduce((acc, workerId) => {
      acc[workerId] = curriculumIds;
      return acc;
    }, {} as WorkerCurriculum);
    return workers;
  }


  const handleSetCurriculums = async () => {
    const workers = createWorkerCurriculumObjectFromCheckedOptions();

    if (noWorkerSelected) return toast.error('ワーカーを選択してください')
    if (curriculumIds.length === 0 || curriculumIds[0] == '') return toast.error('カリキュラムを選択してください')

    if (selectedTab === Tab.Add) {
      registerWorkerCurriculums.mutate({ workerCurriculums: workers })
    } else {
      deleteWorkerCurriculums.mutate({ workerCurriculums: generateDeleteArrayForm(workers) })
    }

  }

  // useEffects
  useEffect(() => {
    setIsLoading(true);
    setAreas(curriculumEditInformationResponse?.areas)
    setShops(curriculumEditInformationResponse?.shops)
    setBrands(curriculumEditInformationResponse?.brands)
    setEmploymentTypes(curriculumEditInformationResponse?.employmentTypes)
    setCheckedOptions([])
    setCheckboxOptions([])
    setIsFormOpen(false)

    if (curriculumEditInformationResponse?.areaId) {
      setAreaId(curriculumEditInformationResponse.areaId)
      setShopId(curriculumEditInformationResponse.shopId)
    }

    setIsLoading(false);

  }, [curriculumEditInformationResponse])

  useEffect(() => {
    const getCourses = async () => {
      setIsLoading(true);
      const { data } = await axios.get<GetShopsResponse>(`/api/shops/courses`)
      const courses = data?.courses

      setCourses([...courses])
    }
    getCourses()
    setIsLoading(false);

  }, [courseId])

  useEffect(() => {
    const getCurriculums = async () => {
      setIsLoading(true);

      let url = `/api/getCurriculums?`
      if (brandIdTarget) {
        url += `brand_id=${brandIdTarget}&`
      }
      if (courseId) {
        url += `course_id=${courseId}`
      }

      const { data } = await axios.get<GetShopsResponse>(url)
      const curriculums = data?.curriculums
      setCurriculums([...curriculums])
    }
    getCurriculums()
    setIsLoading(false);

  }, [brandIdTarget, courseId])

  return (
    <>
      {isLoading || isRequestingWorkerCurriculums ? <Loading /> : ''}
      <link rel="stylesheet" href="/css/pages/shop_select.css" />
      <div className="container2"
        style={{
          textAlign: 'left',
          width: '55%',
          backgroundColor: '#EEEEEE',
          padding: '30px 20px',
          margin: '10px auto',
          borderRadius: '10px',
          minHeight: '600px'
        }}>
        <div>
          <NavLink
            to={`/shop_select`}
            style={{ ...StButtonTypeC, ...{ 'width': '46px', 'padding': '10px 26px' } }}
          >
            {config?.labels?.shop}選択に戻る
          </NavLink>
        </div>

        <div className="tabs" style={{ marginTop: '50px', marginBottom: '30px' }}>
          <div
            className={selectedTab === Tab.Add ? 'tab-item active' : 'tab-item'}
            onClick={() => setSelectedTab(Tab.Add)}
          >
            カリキュラム追加
          </div>
          <div
            className={selectedTab === Tab.Remove ? 'tab-item active' : 'tab-item'}
            onClick={() => setSelectedTab(Tab.Remove)}
          >
            カリキュラム削除
          </div>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          {selectedTab === Tab.Add ? (
            <h1>カリキュラム追加</h1>
          ) : (
            <h1>カリキュラム削除</h1>
          )}
        </div>
        <div style={{ padding: '0 50px' }}>
          <form style={{ display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }} onSubmit={(e) => e.preventDefault()}>
            <div>
              <h2 style={{ marginRight: '5px', marginBottom: '3px' }}>{config?.labels?.area}</h2>
              <select
                style={{ width: '180px !important' }}
                className="parent"
                required
                value={areaId}
                onChange={(e) => fetchShops(e)}
              >
                <option value="" className="msg">
                  -----{config?.labels?.area}を選択-----
                </option>
                {areas?.map((area) => {
                  return (
                    <option key={area.id} value={area.id}>
                      {area.name}
                    </option>
                  )
                })}
              </select>
            </div>

            <div>
              <h2 style={{ marginRight: '5px', marginBottom: '3px' }}>{config?.labels?.shop}</h2>
              <select
                style={{ width: '180px !important' }}
                className="children"
                value={shopId}
                onChange={(e) => {
                  setBrandId(undefined)
                  fetchBrands(e)
                }}
              >
                <option value="" className="msg">
                  -----{config?.labels?.shop}を選択-----
                </option>
                {shops?.map((shop) => {
                  return (
                    <option key={shop.id} value={shop.id}>
                      {shop.name}
                    </option>
                  )
                })}
              </select>
            </div>

            <div>
              <h2 style={{ marginRight: '5px', marginBottom: '3px' }}>{config?.labels?.brand}</h2>
              <select
                style={{ width: '180px !important' }}
                className="children2"
                value={brandId}
                onChange={(e) => {
                  setBrandId(e.target.value)
                }
                }
              >
                <option value="" className="msg">
                  -----{config?.labels?.brand}を選択-----
                </option>
                {brands?.map((brand) => {
                  return (
                    <option key={brand.id} value={brand.id}>
                      {brand.name}
                    </option>
                  )
                })}
              </select>
            </div>

            <div>
              <h2 style={{ marginRight: '5px', marginBottom: '3px' }}>職位</h2>
              <select
                style={{ width: '180px !important' }}
                className="children2"
                value={employmentTypeId}
                onChange={(e) => setEmploymentTypeId(e.target.value)}
              >
                <option value="" className="msg">
                  -----職位を選択-----
                </option>
                {employmentTypes?.map((employment_type) => {
                  return (
                    <option key={employment_type.id} value={employment_type.id}>
                      {employment_type.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </form>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <button
              style={{ maxWidth: '110px' }}
              onClick={(e) => { e.preventDefault(); handleGetWorkers(); }}
              className="updateCurriculumButtonEdit"
            >
              対象者抽出
            </button>
          </div>
          <div
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              marginTop: '30px',
              borderRadius: '5px',
              overflowY: 'auto',
              maxHeight: '400px',
              backgroundColor: '#fff',
              minHeight: '27px'
            }}
          >
            {checkboxOptions.map((option) => (
              <div key={option.id} style={{ display: 'inline-block', width: '200px', padding: '3px', overflowX: 'hidden', marginRight: '5px' }}>
                <label htmlFor={option.id} style={{ marginLeft: '3px', whiteSpace: 'nowrap' }}>
                  <input
                    type="checkbox"
                    id={option.id}
                    checked={checkedOptions.includes(option.id)}
                    onChange={() => handleChange(option.id)}
                  />
                  {option.label}
                </label>
              </div>
            ))
            }
            {(hasNoWorkerOption) &&
              <div style={{ textAlign: 'center' }}>対象者がいません</div>
            }
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'

            }}
          >
            <button
              style={selectAllButton}
              disabled={checkboxOptions.length === 0}
              onClick={isChecked ? deselectAllWorkers : selectAllWorkers}
              className="updateCurriculumButtonEdit"
            >
              {isChecked ? '全解除' : '全選択'}
            </button>

            <button
              style={commonButtonStyle}
              className="updateCurriculumButtonEdit"
              onClick={(e) => { e.preventDefault(); handleSetWorkers(); }}
              disabled={noWorkerSelected}
            >
              対象者の決定
            </button>
          </div>

          <div
            style={{ display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }}
          >
            <div>
              <h2 style={{ marginRight: '5px', marginBottom: '3px' }}>{config?.labels?.brand}</h2>
              <select
                style={{ width: '180px !important' }}
                className="children2"
                disabled={!isFormOpen}
                value={brandIdTarget}
                onChange={(e) => setBrandIdTarget(e.target.value)}
              >
                <option value="" className="msg">
                  -----{config?.labels?.brand}を選択-----
                </option>
                {brands?.map((brand) => {
                  return (
                    <option key={brand.id} value={brand.id}>
                      {brand.name}
                    </option>
                  )
                })}
              </select>
            </div>

            <div >
              <h2 style={{ marginRight: '5px', marginBottom: '3px' }}>コース</h2>
              <select
                style={{ width: '180px !important' }}
                className="children2"
                disabled={!isFormOpen}
                value={courseId}
                onChange={(e) => setCourseId(e.target.value)}
              >
                <option value="" className="msg">
                  -----コースを選択-----
                </option>
                {courses?.map((course) => {
                  return (
                    <option key={course.id} value={course.id}>
                      {course.name}
                    </option>
                  )
                })}
              </select>
            </div>

            <div>
              <h2 style={{ marginRight: '5px', marginBottom: '3px' }}>カリキュラム</h2>
              <select
                style={{ width: '180px !important' }}
                className="children2"
                disabled={!isFormOpen}
                value={curriculumIds}
                onChange={(e) => setCurriculumIds([e.target.value])}
              >
                <option value="" className="msg">
                  -----カリキュラムを選択-----
                </option>
                {curriculums?.map((curriculum) => {
                  return (
                    <option key={curriculum.id} value={curriculum.id}>
                      {curriculum.name}
                    </option>
                  )
                })}
              </select>
            </div>

          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <button
              style={sendButtonTypeStyle}
              className="updateCurriculumButtonEdit"
              onClick={(e) => { e.preventDefault(); handleSetCurriculums(); }}
              disabled={noCurriculumSelected}
            >
              実行
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CurriculumEdit
