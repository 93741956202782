import { FormEvent, useEffect, useState } from 'react'
import Loading from '../Loading'
import { useReloadRelationCurriculum } from '../../queries/WorkersQuery'
import { ScFormItem, StButtonTypeB } from '../../style/styles'

type ReloadRelationCurriculumModalProps = {
  onSuccess: () => void
  closeModal: () => void
}

function ReloadRelationCurriculumModal(props: ReloadRelationCurriculumModalProps) {

  const reloadRelationCurriculum = useReloadRelationCurriculum()

  const [curriculumId, setCurriculumId] = useState<string>('');


  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    reloadRelationCurriculum.mutate({
      curriculumId: curriculumId
    })
  }

  useEffect(() => {
    if (reloadRelationCurriculum.isSuccess) {
      props.onSuccess()
    }
  },[reloadRelationCurriculum.isSuccess])

  return (
    <div className="modal">
      <div className="modal-close">
        <button type="button" className="modal-close-button" onClick={props.closeModal}>
          <img src="/images/circle-xmark.svg" className="modal-close-icon" />
        </button>
      </div>
      <div className="modal-header">
        <h1>カリキュラム情報更新</h1>
      </div>

      <form onSubmit={handleSubmit}>
        {reloadRelationCurriculum.isLoading && <Loading />}
        <fieldset style={{textAlign: 'center'}}>
          <ScFormItem>
            <p>Curriculum ID:</p>
            <input 
              style={{
                margin: '10px auto',
                padding: '10px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '5px',
              }}

              type="text" value={curriculumId} onChange={(e) => setCurriculumId(e.target.value)}/>
          </ScFormItem>
          <div style={{ color: 'red', fontSize: '12px', marginBottom:'10px'}}>
            注意：この操作には数分かかる場合があります。
          </div>
          
          <div className="submit">
            <input type="submit" style={StButtonTypeB} value="送信" />
          </div>
        </fieldset>
      </form>
    </div>
  )
}

export { ReloadRelationCurriculumModal }
