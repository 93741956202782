import React, { FormEvent, useEffect, useState } from 'react'
import { useAuthUser } from '../hooks/AuthUserContext'
import Loading from '../components/Loading'
import {
  useCreateStore1User,
  useCreateTrainerUser,
  useCreateWorkerUser,
  useGetUser,
  useUpdateStore1User,
  useUpdateTrainerUser,
  useUpdateWorkerUser,
} from '../queries/UsersQuery'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useGetShops } from '../queries/ShopsQuery'
import {
  ScButtonTypeA,
  ScFormButtons,
  ScFormContainer,
  ScFormItem,
  ScHeadline,
  ScPageTitleTypeA,
} from '../style/styles'
import { UserRole } from '../types/UserRole'
import useConfig from '../hooks/GetConfig'

function UserEdit() {
  const { userId } = useParams()
  const { authUser } = useAuthUser()
  const {
    isLoading: isLoadingUser,
    isFetching,
    refetch,
    data: user,
  } = useGetUser({ userId })
  const { isLoading: isLoadingShops, data: shops } = useGetShops()
  const config = useConfig()

  const createWorkerUser = useCreateWorkerUser()
  const createTrainerUser = useCreateTrainerUser()
  const updateWorkerUser = useUpdateWorkerUser()
  const updateTrainerUser = useUpdateTrainerUser()
  const createStore1User = useCreateStore1User()
  const updateStore1User = useUpdateStore1User()

  const navigate = useNavigate()

  const [isShowPassword, setIsShowPassword] = useState(0);
  const [selectedEmploymentTypeIds, setSelectedEmploymentTypeIds] = useState<string[]>([]);

  const [editUser, setEditUser] = useState<{
    userId: string
    username: string
    password: string
    confirmPassword: string
    role: string
    shopId: string
    brandId: string
    name: string
    email: string
    employmentTypeIds: string[]
  }>({
    userId: '',
    username: '',
    password: '',
    confirmPassword: '',
    role: '',
    shopId: '',
    brandId: '',
    name: '',
    email: '',
    employmentTypeIds: [''],
  })
  
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);

  useEffect(() => {
    setEditUser({
      userId: user?.id || '',
      username: user?.username || '',
      password: '',
      confirmPassword: '',
      role: user?.role || '',
      shopId: user?.shopId || '',
      brandId: user?.brandId || '',
      name: user?.name || '',
      email: user?.email || '',
      employmentTypeIds: user?.employmentTypeIds || [''],
    })
    setSelectedEmploymentTypeIds(user?.employmentTypeIds || []);
  }, [user])

  useEffect(() => {
    if (
      createWorkerUser.isSuccess ||
      createTrainerUser.isSuccess ||
      updateWorkerUser.isSuccess ||
      updateTrainerUser.isSuccess
    ) {
      navigate(`/users`)
    }
  }, [
    createWorkerUser.isSuccess,
    createTrainerUser.isSuccess,
    updateWorkerUser.isSuccess,
    updateTrainerUser.isSuccess,
  ])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    switch (editUser.role) {
      case UserRole.Worker:
        if (editUser.userId) {
          updateWorkerUser.mutate({
            userId: editUser.userId,
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        } else {
          createWorkerUser.mutate({
            username: editUser.username || '', 
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        }
        break
      case UserRole.Trainer:
        if (editUser.userId) {
          updateTrainerUser.mutate({
            userId: editUser.userId,
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            email: editUser.email || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        } else {
          createTrainerUser.mutate({
            username: editUser.username || '',
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            email: editUser.email || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        }
        break
      case UserRole.Store1:
        if (editUser.userId) {
          updateStore1User.mutate({
            userId: editUser.userId,
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            email: editUser.email || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        } else {
          createStore1User.mutate({
            username: editUser.username || '',
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            email: editUser.email || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        }
        break

      default:
        return
    }
  }

  useEffect(() => {
      if(editUser.confirmPassword !== editUser.password) {
          setShowConfirmPasswordError(true)
      } else {
          setShowConfirmPasswordError(false)
      }
  }, [editUser.confirmPassword]);

  return (
    <>
      <ScHeadline />
      <ScFormContainer>
        <ScPageTitleTypeA>
          {editUser?.userId ? '【 ユーザー変更 】' : '【 ユーザー登録 】'}
        </ScPageTitleTypeA>
        {isLoadingUser || isLoadingShops || isFetching ? (
          <Loading />
        ) : (
          <form onSubmit={handleSubmit}>
            {(createWorkerUser.isLoading ||
              createTrainerUser.isLoading ||
              updateWorkerUser.isLoading ||
              updateTrainerUser.isLoading) && <Loading />}
            <fieldset>
              <ScFormItem>
                <p>{config?.labels?.shop}</p>
                <select
                  value={editUser.shopId}
                  onChange={(e) =>
                    setEditUser({ ...editUser, shopId: e.target.value })
                  }
                >
                  <option value={``}>選択してください</option>
                  {shops?.shops.map((shop) => {
                    return (
                      <option key={shop.id} value={shop.id}>
                        {shop.name}
                      </option>
                    )
                  })}
                </select>
              </ScFormItem>
              {editUser.shopId && 
                <ScFormItem>
                  <p>{config?.labels?.brand}</p>
                  <select
                    value={editUser.brandId}
                    onChange={(e) =>
                      setEditUser({ ...editUser, brandId: e.target.value })
                    }
                  >
                    <option value={``}>選択してください</option>
                    {shops?.brands.filter( (brand) => brand.shop_id === editUser.shopId ).map((brand) => {
                      return (
                        <option key={brand.id} value={brand.id}>
                          {brand.name}
                        </option>
                      )
                    })}
                  </select>
                </ScFormItem>
              }
              {editUser.userId ? (
                ''
              ) : (
                <ScFormItem>
                  <p>ロール</p>
                  <select
                    value={editUser.role}
                    onChange={(e) =>
                      setEditUser({ ...editUser, role: e.target.value })
                    }
                  >
                    <option>選択してください</option>
                    <option value={UserRole.Worker}>スタッフ</option>
                    <option value={UserRole.Trainer}>トレーナー（店長）</option>
                    <option value={UserRole.Store1}>トレーナー・店長（※一斉追加権限有）</option>
                  </select>
                </ScFormItem>
              )}

              <ScFormItem>
                <p>職位</p>
                {shops?.employmentTypes.map((employmentType) => (
                  <label key={employmentType.id} style={{ display: 'block' }}>
                    <input
                      type="checkbox"
                      value={employmentType.id}
                      checked={selectedEmploymentTypeIds.includes(employmentType.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedEmploymentTypeIds([...selectedEmploymentTypeIds, employmentType.id]);
                        } else {
                          setSelectedEmploymentTypeIds(selectedEmploymentTypeIds.filter((id) => id !== employmentType.id));
                        }
                      }}
                    />
                    {employmentType.name}
                  </label>
                ))}
              </ScFormItem>


              <ScFormItem>
                <p>ユーザーID</p>
                <input
                  disabled={editUser.userId ? true : false}
                  type="text"
                  max={255}
                  value={editUser.username}
                  onChange={(e) =>
                    setEditUser({ ...editUser, username: e.target.value })
                  }
                />
              </ScFormItem>
              <ScFormItem>
                <p>パスワード</p>
                {editUser.userId ? (
                  <p style={{ fontSize: '11px', color: '#FF0000' }}>
                    ※変更する場合のみ入力
                  </p>
                ) : (
                  ''
                )}
                <input
                  type={ isShowPassword ? 'text' : 'password'}
                  name="password"
                  onChange={(e) =>
                    setEditUser({ ...editUser, password: e.target.value })
                  }
                />
              </ScFormItem>
              <ScFormItem>
                <p>パスワード（確認）</p>
                {editUser.userId ? (
                  <p style={{ fontSize: '11px', color: '#FF0000' }}>
                    ※変更する場合のみ入力
                  </p>
                ) : (
                  ''
                )}
                  <input
                      type="password"
                      onChange={(e) =>
                          setEditUser({ ...editUser, confirmPassword: e.target.value })
                      }                  />
                  { showConfirmPasswordError ? <div className="error-message" >パスワードが上記と一致しません</div> : '' }
              </ScFormItem>
              <ScFormItem>
                  <input
                      type="checkbox"
                      value={isShowPassword}
                      id="showPassword"
                      onChange={(e) => setIsShowPassword(e.target.checked ? 1 : 0)}
                  />
                <label htmlFor='showPassword'>パスワード表示する</label>
              </ScFormItem>
              <ScFormItem>
                <p>名前</p>
                <input
                  type="text"
                  max={255}
                  value={editUser.name}
                  onChange={(e) =>
                    setEditUser({ ...editUser, name: e.target.value })
                  }
                />
              </ScFormItem>
              {editUser.role === UserRole.Trainer || editUser.role === UserRole.Store1 && (
                <ScFormItem>
                  <p>メールアドレス</p>
                  <input
                    type="text"
                    max={255}
                    value={editUser.email}
                    onChange={(e) =>
                      setEditUser({ ...editUser, email: e.target.value })
                    }
                  />
                </ScFormItem>
              )}
            </fieldset>
            <ScFormButtons>
              <ScButtonTypeA type="submit">登録</ScButtonTypeA>
              <NavLink to={`/users`}>戻る</NavLink>
            </ScFormButtons>
          </form>
        )}
      </ScFormContainer>
    </>
  )
}

export default UserEdit
