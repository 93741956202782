import axios from 'axios'
import { User } from '../types/User'

const getUsers = async () => {
  const { data } = await axios.get<User[]>(`/api/users`)
  return data
}

const getUser = async ({ userId }: { userId: string | undefined }) => {
  if (!userId) return

  const { data } = await axios.get<User>(`/api/users/${userId}`)
  return data
}

const getPasswordConfig = async () => {
  const { data } = await axios.get<any>(`/api/getPasswordConfig`)
  return data
}

const createWorkerUser = async ({
  username,
  password,
  confirmPassword,
  shopId,
  brandId,
  name,
  employmentTypeIds
}: {
  username: string
  password: string | undefined
  confirmPassword: string | undefined
  shopId: string
  brandId: string
  name: string
  employmentTypeIds: string[]
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.post<{}>(`/api/users/worker`, {
    username,
    password,
    confirmPassword,
    shopId,
    brandId,
    name,
    employmentTypeIds
  })
  return data
}

const updateWorkerUser = async ({
  userId,
  password,
  confirmPassword,
  shopId,
  brandId,
  name,
  employmentTypeIds
}: {
  userId: string
  password: string | undefined
  confirmPassword: string | undefined
  shopId: string
  brandId: string
  name: string
  employmentTypeIds: string[]
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/users/${userId}/worker`, {
    password,
    confirmPassword,
    shopId,
    brandId,
    name,
    employmentTypeIds
  })
  return data
}

const createTrainerUser = async ({
  username,
  password,
  confirmPassword,
  shopId,
  brandId,
  name,
  email,
  employmentTypeIds
}: {
  username: string
  password: string | undefined
  confirmPassword: string | undefined
  shopId: string
  brandId: string
  name: string
  email: string
  employmentTypeIds: string[]
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.post<{}>(`/api/users/trainer`, {
    username,
    password,
    confirmPassword,
    shopId,
    brandId,
    name,
    email,
    employmentTypeIds
  })
  return data
}

const updateTrainerUser = async ({
  userId,
  password,
  confirmPassword,
  shopId,
  brandId,
  name,
  email,
  employmentTypeIds
}: {
  userId: string
  password: string | undefined
  confirmPassword: string | undefined
  shopId: string
  brandId: string
  name: string
  email: string
  employmentTypeIds: string[]
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/users/${userId}/trainer`, {
    password,
    confirmPassword,
    shopId,
    brandId,
    name,
    email,
    employmentTypeIds
  })
  return data
}

const createStore1User = async ({
  username,
  password,
  confirmPassword,
  shopId,
  brandId,
  name,
  email,
  employmentTypeIds
}: {
  username: string
  password: string | undefined
  confirmPassword: string | undefined
  shopId: string
  brandId: string
  name: string
  email: string
  employmentTypeIds: string[]
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.post<{}>(`/api/users/store1`, {
    username,
    password,
    confirmPassword,
    shopId,
    brandId,
    name,
    email,
    employmentTypeIds
  })
  return data
}

const updateStore1User = async ({
  userId,
  password,
  confirmPassword,
  shopId,
  brandId,
  name,
  email,
  employmentTypeIds
}: {
  userId: string
  password: string | undefined
  confirmPassword: string | undefined
  shopId: string
  brandId: string
  name: string
  email: string
  employmentTypeIds: string[]
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/users/${userId}/store1`, {
    password,
    confirmPassword,
    shopId,
    brandId,
    name,
    email,
    employmentTypeIds
  })
  return data
}

const deleteUser = async ({ userId }: { userId: string }) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.delete<{}>(`/api/users/${userId}`)
  return data
}

const resetPassword = async ({ userId }: { userId: string }) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/users/password_reset`, {
    username: userId,
  })
  return data
}

const changePassword = async ({
  userId,
  password,
  confirmPassword,
}: {
  userId: string
  password: string
  confirmPassword: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/users/${userId}/password_change`, {
    password,
    confirmPassword,
  })
  return data
}

export {
  getUsers,
  getUser,
  getPasswordConfig,
  createWorkerUser,
  updateWorkerUser,
  createTrainerUser,
  updateTrainerUser,
  deleteUser,
  resetPassword,
  changePassword,
  createStore1User,
  updateStore1User
}
