import React, { useEffect, useState } from 'react'
import { TaskType } from '../types/TaskType'
import { TaskInstance } from '../types/TaskInstance'
import { useModal } from 'react-hooks-use-modal'
import AimSettingModal from './modal/AimSettingModal'
import SelfCheckModal from './modal/SelfCheckModal'
import MovieWatchingModal from './modal/MovieWatchingModal'
import SheetReadingModal from './modal/SheetReadingModal'
import PracticeModal from './modal/PracticeModal'
import FeedbackModal from './modal/FeedbackModal'
import PracticeFeedbackModal from './modal/PracticeFeedbackModal'
import { useAuthUser } from '../hooks/AuthUserContext'
import { TaskInstanceStatus } from '../types/TaskInstanceStatus'
import { UserRole } from '../types/UserRole'
import axios from "axios";
import { CSSProperties } from 'styled-components'

type taskIconProps = {
  taskInstance: TaskInstance
  stepId: string
  stepIsDone: boolean
  onUpdate: () => void
}

function TaskIcon(props: taskIconProps) {
  const { authUser } = useAuthUser()
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  })

  const [canEditAsWorker, setCanEditAsWorker] = useState<boolean>(false)
  const [canEditAsTrainer, setCanEditAsTrainer] = useState<boolean>(false)
  const [link, setLink] = useState('')
  useEffect(() => {
    switch (authUser?.role) {
      case UserRole.Worker:
        setCanEditAsWorker(props.taskInstance.worker.id === authUser?.workerId)
        setCanEditAsTrainer(false)
        break
      case UserRole.Trainer:
      case UserRole.Store1:
        setCanEditAsWorker(props.taskInstance.worker.id === authUser?.workerId)
        setCanEditAsTrainer(props.taskInstance.worker.shopId === authUser?.shopId)
        break
      case UserRole.Manager:
        setCanEditAsWorker(props.taskInstance.worker.shopId === authUser?.shopId)
        setCanEditAsTrainer(props.taskInstance.worker.shopId === authUser?.shopId)
        break
      case UserRole.Admin:
        setCanEditAsWorker(true)
        setCanEditAsTrainer(true)
        break
    }
  }, [authUser])

  let modal
  let imageSrc
  let styleImg
  const date = new Date().toISOString().split("T")[0]
  switch (props.taskInstance.task.type) {
    case TaskType.AimSetting:
      imageSrc = props.taskInstance.isDone
        ? '/img/calc01-a.png'
        : '/img/calc01.png'
        
      imageSrc = (props.taskInstance.endAt < date && !props.stepIsDone)
      ? '/img/calc1.png'
      : imageSrc

      modal = (
        <AimSettingModal
          taskInstance={props.taskInstance}
          closeModal={close}
          stepId={props.stepId}
          onSuccess={async () => {
            close()
            await props.onUpdate()
          }}
          canEditAsWorker={canEditAsWorker}
          canEditAsTrainer={canEditAsTrainer}
        />
      )
      break
    case TaskType.SelfCheck:
      switch (props.taskInstance.status) {
        case TaskInstanceStatus.Undone:
          imageSrc = '/img/calc02.png'
          break
        case TaskInstanceStatus.WorkerDone:
          imageSrc = '/img/calc02-b.png'
          break
        case TaskInstanceStatus.TrainerDone:
          imageSrc = '/img/calc02-a.png'
          break
      }
      modal = (
        <SelfCheckModal
          taskInstance={props.taskInstance}
          closeModal={close}
          onSuccess={async () => {
            close()
            await props.onUpdate()
          }}
          canEditAsWorker={canEditAsWorker}
          canEditAsTrainer={canEditAsTrainer}
        />
      )
      break
    case TaskType.MovieWatching:
      //if thumbUrl its empty, use the old rule to show the icon.
      imageSrc =  props.taskInstance.thumbUrl || (props.taskInstance.isDone ? '/img/calc03-a.png' : '/img/calc03.png')
      styleImg = {
        height: '100px', 
        width: '100px', 
        borderRadius: '50px'
      }
      modal = (
        <MovieWatchingModal
          taskInstance={props.taskInstance}
          closeModal={close}
          onSuccess={async () => {
            close()
            await props.onUpdate()
          }}
          canEditAsWorker={canEditAsWorker}
          canEditAsTrainer={canEditAsTrainer}
        />
      )
      break
    case TaskType.SheetReading:
      imageSrc = props.taskInstance.isDone
        ? '/img/calc04-a.png'
        : '/img/calc04.png'
      modal = (
        <SheetReadingModal
          taskInstance={props.taskInstance}
          closeModal={close}
          onSuccess={async () => {
            close()
            await props.onUpdate()
          }}
          canEditAsWorker={canEditAsWorker}
          canEditAsTrainer={canEditAsTrainer}
        />
      )
      break
    case TaskType.Practice:
      imageSrc = props.taskInstance.isDone
        ? '/img/calc05-a.png'
        : '/img/calc05.png'
      modal = (
        <PracticeModal
          taskInstance={props.taskInstance}
          closeModal={close}
          onSuccess={async () => {
            close()
            await props.onUpdate()
          }}
          canEditAsWorker={canEditAsWorker}
          canEditAsTrainer={canEditAsTrainer}
        />
      )
      break
    case TaskType.Feedback:
      switch (props.taskInstance.status) {
        case TaskInstanceStatus.Undone:
          imageSrc = '/img/calc06.png'
          break
        case TaskInstanceStatus.WorkerDone:
          imageSrc = '/img/calc06-b.png'
          break
        case TaskInstanceStatus.TrainerDone:
          imageSrc = '/img/calc06-a.png'
          break
      }
      modal = (
        <FeedbackModal
          taskInstance={props.taskInstance}
          closeModal={close}
          onSuccess={async () => {
            close()
            await props.onUpdate()
          }}
          canEditAsWorker={canEditAsWorker}
          canEditAsTrainer={canEditAsTrainer}
        />
      )
      break
    case TaskType.PracticeFeedback:
      switch (props.taskInstance.status) {
        case TaskInstanceStatus.Undone:
          imageSrc = '/img/calc07.png'
          break
        case TaskInstanceStatus.WorkerDone:
        case TaskInstanceStatus.TrainerDone:
          imageSrc = '/img/calc07-a.png'
          break
      }
      modal = (
        <PracticeFeedbackModal
          taskInstance={props.taskInstance}
          closeModal={close}
          onSuccess={async () => {
            close()
            await props.onUpdate()
          }}
          canEditAsWorker={canEditAsWorker}
          canEditAsTrainer={canEditAsTrainer}
        />
      )
      break
    case TaskType.Form:
      imageSrc = '/img/calc_form.png'
      break
  }
  return (
    <>
      {props.taskInstance.task.type === TaskType.Form ? (
        <>
          <a
            href={props.taskInstance.task.urlForm}
            className="mypage-task-button"
            target='_blank'
          >
            <span className="mypage-task-title">
              {props.taskInstance.task.name}
            </span>
            <img src={imageSrc} style={styleImg} alt={props.taskInstance.task.name} />
          </a>

        </>
      ) : (
        <>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              open()
            }}
            className="mypage-task-button"
          >
            {props.taskInstance.watchedNum ? (
              <span className="count-num">{props.taskInstance.watchedNum}</span>
            ) : (
              ''
            )}
            {props.taskInstance.readNum ? (
              <span className="count-num">{props.taskInstance.readNum}</span>
            ) : (
              ''
            )}
            <span className="mypage-task-title">
              {props.taskInstance.task.name}
            </span>
            <img src={imageSrc} style={styleImg} alt={props.taskInstance.task.name} />
          </a>
          <Modal>{modal}</Modal>
        </>
      )}
    </>
  )
}

export default TaskIcon
