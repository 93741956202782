import React, { useEffect, useState } from 'react'
import { WorkerListResponse, Worker } from '../types/WorkerListResponse'
import WorkerCurriculumTableBulkAdd from '../components/WorkerCurriculumTableBulkAdd'
import WorkerCurriculumTableBulkRemove from '../components/WorkerCurriculumTableBulkRemove'
import { useRegisterWorkerCurriculum, useDeleteWorkerCurriculum } from '../queries/WorkersQuery'
import { useModal } from 'react-hooks-use-modal'
import { StButtonTypeB, StButtonTypeBDanger } from '../style/styles'

type WorkerCurriculumBulkAddRemoveProps = {
  workerListResponse: WorkerListResponse | undefined,
  onSuccess: () => void,
  showSupports: boolean | undefined,
  workersSorted: Worker[],
}

enum Tab {
  Add,
  Remove,
}

function WorkerCurriculumBulkAddRemove(props: WorkerCurriculumBulkAddRemoveProps) {
  const [workerCurriculums, setWorkerCurriculums] = useState<any>({})
  const [workerCurriculumsToDelete, setWorkerCurriculumsToDelete] = useState<{ workerId: string, curriculumId: string }[]>([])
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Add)

  const registerWorkerCurriculums = useRegisterWorkerCurriculum()
  const deleteWorkerCurriculums = useDeleteWorkerCurriculum()

  useEffect(() => {
    if (registerWorkerCurriculums.isSuccess) {
      props.onSuccess()
    }
  }, [registerWorkerCurriculums.isSuccess])

  useEffect(() => {
    if (deleteWorkerCurriculums.isSuccess) {
      props.onSuccess()
    }
  }, [deleteWorkerCurriculums.isSuccess])

  const submitWorkerCurriculums = () => {
    switch (selectedTab) {
      case Tab.Add:
        registerWorkerCurriculums.mutate({ workerCurriculums: workerCurriculums })
        break;
      case Tab.Remove:
        deleteWorkerCurriculums.mutate({ workerCurriculums: workerCurriculumsToDelete })
        break;
    }
  }

  const [ModalConfirmAdd, openModalConfirmAdd, closeModalConfirmAdd, isOpenModalConfirmAdd] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  })
  const [ModalConfirmRemove, openModalConfirmRemove, closeModalConfirmRemove, isOpenModalConfirmRemove] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  })

  return (
    <>
      <div className="tabs">
        <div
          className={selectedTab === Tab.Add ? 'tab-item active' : 'tab-item'}
          onClick={() => setSelectedTab(Tab.Add)}
        >
          カリキュラム追加
        </div>
        <div
          className={selectedTab === Tab.Remove ? 'tab-item active' : 'tab-item'}
          onClick={() => setSelectedTab(Tab.Remove)}
        >
          カリキュラム削除
        </div>
        <button
          className="tab-button"
          onClick={() => {
            switch (selectedTab) {
              case Tab.Add:
                openModalConfirmAdd()
                break
              case Tab.Remove:
                openModalConfirmRemove()
                break
            }
          }}
        >
          設定する
        </button>
      </div>

      {selectedTab === Tab.Add && (
        <WorkerCurriculumTableBulkAdd
          workerListResponse={props.workerListResponse}
          workerCurriculums={workerCurriculums}
          setWorkerCurriculums={setWorkerCurriculums}
          showSupports={props.showSupports}
          workers={props.workersSorted}
        />
      )}
      {selectedTab === Tab.Remove && (
        <WorkerCurriculumTableBulkRemove
          workerListResponse={props.workerListResponse}
          workerCurriculums={workerCurriculumsToDelete}
          setWorkerCurriculums={setWorkerCurriculumsToDelete}
          showSupports={props.showSupports}
          workers={props.workersSorted}
        />
      )}

      <ModalConfirmAdd>
        <div className="modal">
          <div className="modal-close">
            <button type="button" className="modal-close-button" onClick={(e) => { closeModalConfirmAdd() }}>
              <img src="/images/circle-xmark.svg" className="modal-close-icon" />
            </button>
          </div>
          <div className="modal-header">
            <h1>カリキュラム一括追加</h1>
          </div>

          <div style={{ paddingBottom: '10px' }}>
            <p> 選択されているカリキュラムを追加します。</p>
            <br />
            <p>よろしいですか？</p>
          </div>

          <div>
            <button
              style={StButtonTypeB}
              onClick={() => {
                closeModalConfirmAdd()
                submitWorkerCurriculums()
              }}
            >
              追加する！
            </button>
          </div>

        </div>
      </ModalConfirmAdd>
      <ModalConfirmRemove>
        <div className="modal">
          <div className="modal-close">
            <button type="button" className="modal-close-button" onClick={(e) => { closeModalConfirmRemove() }}>
              <img src="/images/circle-xmark.svg" className="modal-close-icon" />
            </button>
          </div>
          <div className="modal-header">
            <h1>カリキュラム一括削除</h1>
          </div>

          <div style={{ paddingBottom: '10px' }}>
            <p> 選択されているカリキュラムを削除します。</p>
            <br />
            {/* {hasWorkerCurriculumsToDeleteWithDoneSteps() && (
              <>
                <p style={{ color: 'red' }}>選択されたカリキュラムに履修済みが含まれています。</p>
                <br />
              </>
            )} */}
            <p>よろしいですか？</p>
          </div>

          <div>
            <button
              style={StButtonTypeBDanger}
              onClick={() => {
                closeModalConfirmRemove()
                submitWorkerCurriculums()
              }}
            >
              削除する！
            </button>
          </div>

        </div>
      </ModalConfirmRemove>

    </>
  )
}

export default WorkerCurriculumBulkAddRemove
