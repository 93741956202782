import axios from 'axios'

const updateAim = async ({
  workerId,
  stepId,
  taskId,
  aim,
  startAt,
  endAt,
}: {
  workerId: string
  stepId: string
  taskId: string
  aim: string
  startAt: string
  endAt: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/aim`, {
    workerId,
    stepId,
    taskId,
    aim,
    startAt,
    endAt,
  })
  return data
}

const updateSelfChecks = async ({
  workerId,
  taskId,
  selfCheckItemIds,
  confirm,
}: {
  workerId: string
  taskId: string
  selfCheckItemIds: Array<string>
  confirm: boolean
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/selfChecks`, {
    workerId,
    taskId,
    checkedByWorkerItemIds: selfCheckItemIds,
    confirm,
  })
  return data
}

const updateTrainerChecks = async ({
  workerId,
  taskId,
  trainerCheckedItemIds,
  trainerComment,
  confirm,
}: {
  workerId: string
  taskId: string
  trainerCheckedItemIds: Array<string>
  trainerComment: string
  confirm: boolean
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/selfChecks/trainer`, {
    workerId,
    taskId,
    checkedByTrainerItemIds: trainerCheckedItemIds,
    trainerComment,
    confirm,
  })
  return data
}

const markMovieAsWatched = async ({
  workerId,
  taskId,
}: {
  workerId: string
  taskId: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/markMovieAsWatched`, {
    workerId,
    taskId,
  })
  return data
}

const markSheetAsRead = async ({
  workerId,
  taskId,
}: {
  workerId: string
  taskId: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/markSheetAsRead`, {
    workerId,
    taskId,
  })
  return data
}

const updatePracticeWorkerComment = async ({
  workerId,
  taskId,
  comment,
}: {
  workerId: string
  taskId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/practice`, {
    workerId,
    taskId,
    comment: comment,
  })
  return data
}

const updateFeedbackWorkerComment = async ({
  workerId,
  taskId,
  comment,
}: {
  workerId: string
  taskId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/feedback`, {
    workerId,
    taskId,
    comment: comment,
  })
  return data
}

const updatePracticeFeedbackWorkerComment = async ({
  workerId,
  taskId,
  comment,
}: {
  workerId: string
  taskId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/practiceFeedback`, {
    workerId,
    taskId,
    comment: comment,
  })
  return data
}

const updateFeedbackTrainerComment = async ({
  workerId,
  taskId,
  comment,
}: {
  workerId: string
  taskId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/feedback/trainer`, {
    workerId,
    taskId,
    comment: comment,
  })
  return data
}

const updatePracticeFeedbackTrainerComment = async ({
  workerId,
  taskId,
  comment,
}: {
  workerId: string
  taskId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/practiceFeedback/trainer`, {
    workerId,
    taskId,
    comment: comment,
  })
  return data
}

export {
  updateAim,
  updateSelfChecks,
  updateTrainerChecks,
  markMovieAsWatched,
  markSheetAsRead,
  updatePracticeWorkerComment,
  updateFeedbackWorkerComment,
  updatePracticeFeedbackWorkerComment,
  updateFeedbackTrainerComment,
  updatePracticeFeedbackTrainerComment,
}
