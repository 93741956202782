import React, { FormEvent, useEffect, useState } from 'react'
import { useResetPassword } from '../queries/UsersQuery'
import Loading from '../components/Loading'
import { useNavigate } from 'react-router-dom'

const PasswordReset: React.VFC = () => {
  const [username, setUsername] = useState<string>('')

  const resetPassword = useResetPassword()
  const navigate = useNavigate()

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    resetPassword.mutate({ userId: username })
  }

  useEffect(() => {
    if (resetPassword.isSuccess) {
      navigate(`/`)
    }
  }, [resetPassword.isSuccess])

  return (
    <>
      {resetPassword.isLoading && <Loading />}
      <link rel="stylesheet" href="/css/pages/recovery.css" />
      <div className="container" style={{ width: 'auto', textAlign: 'center' }}>
        <p style={{ lineHeight: 1.8 }}>
          パスワードをリセットします。
          <br />
          店舗管理者から仮パスワードを確認してください。
        </p>
        <div className="users form" style={{ marginTop: '50px' }}>
          <form onSubmit={handleSubmit}>
            <fieldset>
              <div className="text_id" style={{ paddingBottom: '10px' }}>
                ユーザー（社員番号）を入力してください
              </div>
              <div className="ID">
                <div className="input text">
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
            </fieldset>
            <div className="submit">
              <input
                type="submit"
                value="パスワードリセット"
                style={{
                  fontSize: '14px',
                  padding: '5px 20px',
                  background: '#3d9be8',
                  color: '#fff',
                  margin: '50px auto 0',
                  borderRadius: '10px',
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default PasswordReset
