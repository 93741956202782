import React, { FormEvent, useState } from 'react'
import { useLogin } from '../queries/AuthQuery'
import { Navigate, NavLink } from 'react-router-dom'
import { useAuthUser } from '../hooks/AuthUserContext'
import Loading from '../components/Loading'
import '../css/pages/login.css'
import { UserRole } from '../types/UserRole'

const Login: React.VFC = () => {
  const login = useLogin()
  const { authUser } = useAuthUser()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')


  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    login.mutate({ username, password })
  }

  if (authUser?.id && authUser?.role) {
    switch (authUser.role) {
      case UserRole.Worker:
        return <Navigate to={`/worker_detail/${authUser.workerId}`} />
      case UserRole.Trainer:
      case UserRole.Store1:
        return <Navigate to={`/shop/${authUser.shopId}/worker_list/${authUser?.worker.brand_id}`} />
      case UserRole.Manager:
      case UserRole.Admin:
        return <Navigate to={`/shop_select`} />
    }
  }

  return (
    <>
      {login.isLoading && <Loading />}
      <div style={{ background: '#ff0000', padding: '5px' }}></div>
      <div className="container-login">
        <div className="users form">
          <form onSubmit={handleSubmit}>
            <fieldset>
              <div className="text_id" style={{ paddingBottom: '10px' }}>
                ユーザー（社員番号）
              </div>
              <div className="ID">
                <div className="input text">
                  <input
                    type="text"
                    name="username"
                    autoComplete="off"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
              <>
              <div
                className="text_id"
                style={{ marginTop: '20px', paddingBottom: '10px' }}
              >
                パスワード
              </div>
              <div className="ID">
                <div className="input text">
                  <input
                    type="password"
                    name="password"
                    autoComplete="off"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              </>
            </fieldset>
            <div className="submit">
              <input type="submit" id="ID_Login" value="ログイン" />
            </div>
          </form>
          <div style={{ textAlign: 'center', fontSize: '13px' }}>
            <NavLink
              style={{
                display: 'inline-block',
                margin: '30px auto',
              }}
              to={`/password_reset`}
            >
              パスワードリセットはこちら
            </NavLink>
          </div> 
        </div>
      </div>
    </>
  )
}

export default Login
