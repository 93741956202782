import React, { FormEvent, useEffect, useState } from 'react'
import { TaskInstance } from '../../types/TaskInstance'
import {
  useUpdateFeedbackTrainerComment,
  useUpdateFeedbackWorkerComment,
} from '../../queries/TasksQuery'
import Loading from '../Loading'
import { StButtonTypeB } from '../../style/styles'

type FeedbackModalProps = {
  taskInstance: TaskInstance
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function FeedbackModal(props: FeedbackModalProps) {
  const [workerComment, setWorkerComment] = useState<string>(
    props.taskInstance.workerComment || ''
  )
  const [trainerComment, setTrainerComment] = useState<string>(
    props.taskInstance.trainerComment || ''
  )
  const updateFeedbackWorkerComment = useUpdateFeedbackWorkerComment()
  const updateFeedbackTrainerComment = useUpdateFeedbackTrainerComment()

  const handleSubmitEditAsWorker = (e: FormEvent) => {
    e.preventDefault()
    updateFeedbackWorkerComment.mutate({
      workerId: props.taskInstance.worker.id,
      taskId: props.taskInstance.task.id,
      comment: workerComment,
    })
  }

  const handleSubmitEditAsTrainer = (e: FormEvent) => {
    e.preventDefault()
    updateFeedbackTrainerComment.mutate({
      workerId: props.taskInstance.worker.id,
      taskId: props.taskInstance.task.id,
      comment: trainerComment,
    })
  }

  useEffect(() => {
    if (
      updateFeedbackWorkerComment.isSuccess ||
      updateFeedbackTrainerComment.isSuccess
    ) {
      props.onSuccess()
    }
  }, [
    updateFeedbackWorkerComment.isSuccess,
    updateFeedbackTrainerComment.isSuccess,
  ])

  return (
    <>
      <link rel="stylesheet" href="/css/components/modal/feedback_modal.css" />
      {updateFeedbackWorkerComment.isLoading ? <Loading /> : ''}
      <div className="modal">
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.closeModal}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          <h1> {props.taskInstance.task.name} </h1>
        </div>

        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <fieldset>
              <div>
                <textarea
                  disabled={!props.canEditAsWorker}
                  rows={5}
                  value={workerComment}
                  onChange={(e) => setWorkerComment(e.target.value)}
                />
              </div>
              <div style={{ marginTop: '30px' }}>
                トレーナーコメント
                <textarea
                  disabled={!props.canEditAsTrainer}
                  rows={5}
                  value={trainerComment}
                  onChange={(e) => setTrainerComment(e.target.value)}
                />
              </div>
              {props.canEditAsWorker && (
                <button
                  type="submit"
                  style={StButtonTypeB}
                  onClick={handleSubmitEditAsWorker}
                >
                  スタッフ送信
                </button>
              )}
              {props.canEditAsTrainer && (
                <button
                  type="submit"
                  style={StButtonTypeB}
                  onClick={handleSubmitEditAsTrainer}
                >
                  トレーナー送信
                </button>
              )}
            </fieldset>
          </form>
        </div>
      </div>
    </>
  )
}

export default FeedbackModal
