import React, { FormEvent, useEffect, useState } from 'react'
import { TaskInstance } from '../../types/TaskInstance'
import { SelfCheckItem } from '../../types/SelfCheckItem'
import {
  useUpdateSelfChecks,
  useUpdateTrainerChecks,
} from '../../queries/TasksQuery'
import Loading from '../Loading'
import {StButtonTypeA, StButtonTypeB, StButtonTypeC, StButtonTypeBDisabled} from '../../style/styles'
import { useAuthUser } from '../../hooks/AuthUserContext'
import { UserRole } from '../../types/UserRole'
import { TaskType } from '../../types/TaskType'
import { TaskInstanceStatus } from '../../types/TaskInstanceStatus'


type SelfCheckModalProps = {
  taskInstance: TaskInstance
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function SelfCheckModal(props: SelfCheckModalProps) {
  const { authUser } = useAuthUser()
  const [selfCheckItemIds, setSelfCheckItemIds] = useState<string[]>(
    props.taskInstance.selfCheckedItems?.map((item) => item.id) || []
  )
  const [trainerCheckItemIds, setTrainerCheckItemIds] = useState<string[]>(
    props.taskInstance.trainerCheckedItems?.map((item) => item.id) || []
  )
  const [trainerComment, setTrainerComment] = useState<string>(
    props.taskInstance.trainerComment || ''
  )
  const [canEditAsWorker, setCanEditAsWorker] = useState<boolean>(
   props.canEditAsWorker
  )
  const [canEditAsTrainer, setCanEditAsTrainer] = useState<boolean>(
    props.canEditAsTrainer
  )

  const [checkAll, setCheckAll] = useState<boolean>(false)

  const updateSelfChecks = useUpdateSelfChecks()
  const updateTrainerChecks = useUpdateTrainerChecks()

  const disabledInputWorker = function(): boolean {
    if (trainerCheckItemIds.length > 0 || !canEditAsWorker) {
          return true;
      } else {
          return false;
      }
  }

  const disabledInputTrainer = function(): boolean {
    // @ts-ignore
    if (props.canEditAsTrainer && props.taskInstance.status >= TaskInstanceStatus.WorkerDone) {
      return false;
    }else{
      return true;
    }
  }

  useEffect(() => {
      if (updateSelfChecks.isSuccess || updateTrainerChecks.isSuccess) {
      props.onSuccess()
    }
  }, [updateSelfChecks.isSuccess, updateTrainerChecks.isSuccess, authUser])

  const handleCheckByWorker = (
    checked: boolean,
    selfCheckItem: SelfCheckItem
  ) => {
    let itemIds = [...selfCheckItemIds]
    if (checked) {
      if (!itemIds.includes(selfCheckItem.id)) itemIds.push(selfCheckItem.id)
      setSelfCheckItemIds(itemIds)
    } else {
      itemIds = itemIds.filter((id) => id !== selfCheckItem.id)
      setSelfCheckItemIds(itemIds)
    }
  }

  const handleCheckByTrainer = (
    checked: boolean,
    selfCheckItem: SelfCheckItem
  ) => {
    let itemIds = [...trainerCheckItemIds]
    if (checked) {
      if (!itemIds.includes(selfCheckItem.id)) itemIds.push(selfCheckItem.id)
      setTrainerCheckItemIds(itemIds)
    } else {
      itemIds = itemIds.filter((id) => id !== selfCheckItem.id)
      setTrainerCheckItemIds(itemIds)
    }
  }

  const handleSaveAsWorker = (e: FormEvent) => {
    e.preventDefault()
    updateSelfChecks.mutate({
      workerId: props.taskInstance.worker.id,
      taskId: props.taskInstance.task.id,
      selfCheckItemIds: selfCheckItemIds,
      confirm: false,
    })
  }
  const handleSaveAsTrainer = (e: FormEvent) => {
    e.preventDefault()
    updateTrainerChecks.mutate({
      workerId: props.taskInstance.worker.id,
      taskId: props.taskInstance.task.id,
      trainerCheckedItemIds: trainerCheckItemIds,
      trainerComment,
      confirm: false,
    })
  }
  const handleSubmitAsWorker = (e: FormEvent) => {
    e.preventDefault()
    updateSelfChecks.mutate({
      workerId: props.taskInstance.worker.id,
      taskId: props.taskInstance.task.id,
      selfCheckItemIds: selfCheckItemIds,
      confirm: true,
    })
  }
  const handleSubmitAsTrainer = (e: FormEvent) => {
    e.preventDefault()

    updateTrainerChecks.mutate({
      workerId: props.taskInstance.worker.id,
      taskId: props.taskInstance.task.id,
      trainerCheckedItemIds: trainerCheckItemIds,
      trainerComment: trainerComment,
      confirm: true,
    })
  }

  useEffect(() => {
    const isAllItemsChecked = props.taskInstance.task.selfCheckItems?.length === trainerCheckItemIds.length
    setCheckAll(isAllItemsChecked);
  }, [props.taskInstance, trainerCheckItemIds]);

  const handleCheckAllTrainer = () => {
    let itemIds:string[] = [];

    if(!checkAll){
      props.taskInstance.task.selfCheckItems?.map(function (selfCheckItem) {
        itemIds.push(selfCheckItem.id)
      })
      setTrainerCheckItemIds(itemIds)
      return;
    }
    setTrainerCheckItemIds([]);
    
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="/css/components/modal/self_check_modal.css"
      />
      {updateSelfChecks.isLoading ? <Loading /> : ''}
      <div className="modal">
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.closeModal}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          <h1> {props.taskInstance.task.name} </h1>
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          <table className="selfchecktable">
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td
                  style={{
                    fontSize: '10px',
                    padding: '0 5px',
                    textAlign: 'center',
                  }}
                >
                  トレーナー
                </td>
              </tr>
              {props.taskInstance.task.selfCheckItems?.map((selfCheckItem) => {
                return (
                  <tr key={selfCheckItem.id}>
                    <td>{selfCheckItem.content}</td>
                    <td>
                      <div className="input checkbox">
                        <input

                          type="checkbox"
                          checked={selfCheckItemIds.includes(selfCheckItem.id)}
                          onChange={(e) => {
                            handleCheckByWorker(e.target.checked, selfCheckItem)
                          }}
                          disabled={disabledInputWorker()}
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={trainerCheckItemIds.includes(selfCheckItem.id)}
                        onChange={(e) => {
                          handleCheckByTrainer(e.target.checked, selfCheckItem)
                        }}
                        disabled={disabledInputTrainer()}
                      />
                    </td>
                  </tr>
                )
              })}
              <tr>
                
                <td></td>
                <td></td>
                <td>
                  {props.canEditAsTrainer ?
                    <button 
                      style={{
                        borderRadius: '5px',
                        fontSize: '12px',
                        cursor: 'pointer',
                      }}
                      onClick={handleCheckAllTrainer} 
                      disabled={disabledInputTrainer()}
                    >{checkAll ? '全解除' : '全選択'}</button>
                  : ''}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ marginTop: '30px' }}>
            トレーナーコメント
            <textarea
              rows={5}
              value={trainerComment}
              onChange={(e) => setTrainerComment(e.target.value)}
              disabled={!canEditAsTrainer}
            />
            <div className="self-check-modal-confirm-buttons">
              <div>
                <div>
                  セルフチェック
                </div>
                <div>
                  <button
                    type="submit"
                    style={!canEditAsWorker ? StButtonTypeBDisabled : StButtonTypeB}
                    onClick={handleSaveAsWorker}
                    disabled={!canEditAsWorker}
                  >
                    保存
                  </button>
                  <button
                    type="submit"
                    style={!((selfCheckItemIds?.length == props.taskInstance.task.selfCheckItems?.length) && canEditAsWorker) ? StButtonTypeBDisabled : StButtonTypeB}
                    onClick={handleSubmitAsWorker}
                    disabled={!((selfCheckItemIds?.length == props.taskInstance.task.selfCheckItems?.length) && canEditAsWorker)}
                  >
                    完了
                  </button>
                </div>
              </div>
              <div>
                <div>
                  トレーナーチェック
                </div>
                <div>
                  {props.canEditAsTrainer ?
                    <>
                      <button
                          type="submit"
                          // @ts-ignore
                          style={!(canEditAsTrainer && selfCheckItemIds?.length >= props.taskInstance.task.selfCheckItems?.length
                              && (props.taskInstance.status == TaskInstanceStatus.WorkerDone || props.taskInstance.status == TaskInstanceStatus.TrainerDone)) ? StButtonTypeBDisabled : StButtonTypeB}
                          onClick={handleSaveAsTrainer}
                          // @ts-ignore
                          disabled={!(canEditAsTrainer && selfCheckItemIds?.length >= props.taskInstance.task.selfCheckItems?.length
                              && (props.taskInstance.status == TaskInstanceStatus.WorkerDone || props.taskInstance.status == TaskInstanceStatus.TrainerDone))}
                      >
                        保存
                      </button>
                      <button
                          type="submit"
                          style={!((trainerCheckItemIds?.length == props.taskInstance.task.selfCheckItems?.length) && (canEditAsTrainer)
                          && (selfCheckItemIds?.length >= props.taskInstance.task.selfCheckItems?.length)) ? StButtonTypeBDisabled : StButtonTypeB}
                          disabled={!((trainerCheckItemIds?.length == props.taskInstance.task.selfCheckItems?.length) && (canEditAsTrainer) && (selfCheckItemIds?.length >= props.taskInstance.task.selfCheckItems?.length))}
                          onClick={handleSubmitAsTrainer}
                      >
                        合格
                      </button>
                  </>
                 : '' }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default SelfCheckModal
