import React, { FormEvent, useEffect, useState } from 'react'
import { TaskInstance } from '../../types/TaskInstance'
import { useMarkMovieAsWatched } from '../../queries/TasksQuery'
import Loading from '../Loading'
import ProgressBar from '../ProgressBar'
import {StButtonTypeA, StButtonTypeB, StButtonTypeC, StButtonTypeBDisabled} from '../../style/styles'
import axios from "axios";
import ReactLoading from "react-loading";
import Player from '@vimeo/player';

type MovieWatchingModalProps = {
  taskInstance: TaskInstance
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function MovieWatchingModal(props: MovieWatchingModalProps) {
  const [watched, setWatched] = useState<boolean>(false)

  const MINIMUM_PLAY_PERCENTAGE = 95;

  const markMovieAsWatched = useMarkMovieAsWatched()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    markMovieAsWatched.mutate({
      workerId: props.taskInstance.worker.id,
      taskId: props.taskInstance.task.id,
    })
  }

  useEffect(() => {
    if (markMovieAsWatched.isSuccess) {
      props.onSuccess()
    }
  }, [markMovieAsWatched.isSuccess])

  const [link, setLink] = useState('')
  const [loading, setLoading] = useState(false)
  const [progressVideo, setProgress] = useState<Number[]>([]);
  const [maxHeight, setMaxHeight] = useState(600)

  function vimeoPlayProgressEvent(data: any): any {
    var currentPercentage = Math.ceil(data.percent * 100);
    setProgress((values)=>{
      if (!values.includes(currentPercentage)) {
        values.push(currentPercentage)
      }
      if (values.length >= MINIMUM_PLAY_PERCENTAGE) {
        setWatched(true);
      }
      return [...values]
    });
  }

  function createVimeoPlayer(maxWidth: number, maxHeight: number) {
    setLoading(true)
    axios.get(`/api/movies/${props.taskInstance.task.movie?.id}`).then((response) => {
      setLink(response.data.embedUrl)
      setLoading(false)
      if (props.taskInstance.task.movie?.id) {
        const player = new Player('vimeoPlayer', {
          id: Number(props.taskInstance.task.movie.id),
          autoplay: true,
          maxwidth: maxWidth,
          maxheight: maxHeight,
        })
        player.on('ended', vimeoPlayProgressEvent);
        player.on('timeupdate', vimeoPlayProgressEvent);
      }
    })
  }

  useEffect(() => {
    let maxWidth = window.innerWidth;
    let maxHeight = window.innerHeight;
    if(maxWidth < 1024 || maxHeight < 800) {
      maxWidth = maxWidth - 200;
      maxHeight = maxHeight - 200;
    } else if(maxWidth < 1024 || maxHeight < 1200) {
      maxWidth = 900;
      maxHeight = 800;
    }
    setMaxHeight(maxHeight);
    createVimeoPlayer(maxWidth, maxHeight);
  }, []);

  return (
    <>
      {markMovieAsWatched.isLoading ? <Loading /> : ''}
      <div className="modal">
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.closeModal}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          <h1> {props.taskInstance.task.name} </h1>
        </div>

        {/*<a*/}
        {/*  data-loty="data-loty"*/}
        {/*  href="https://player.vimeo.com/video/348945991?autoplay=1"*/}
        {/*  id="delay11102"*/}
        {/*>*/}
        {/*  {" "}*/}
        {/*  <img src="/img/vtr-dummy.jpg" style={{ width: "100%" }} />*/}
        {/*</a>*/}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            maxHeight: maxHeight,
          }}
        >
          {
            loading ? <ReactLoading type="spin" /> : ""
          }
          <div id="vimeoPlayer"></div>
        </div>
        <ProgressBar data={progressVideo} />
        {watched ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <form onSubmit={handleSubmit}>
              <fieldset disabled={!props.canEditAsWorker}>
                <div className="submit">
                  <input type="submit" style={props.canEditAsWorker ? StButtonTypeB : StButtonTypeBDisabled } value="見たよ" />
                </div>
              </fieldset>
            </form>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default MovieWatchingModal
