import React, { FormEvent, useEffect, useState } from 'react'
import { TaskInstance } from '../../types/TaskInstance'
import { useUpdatePracticeWorkerComment } from '../../queries/TasksQuery'
import Loading from '../Loading'
import { StButtonTypeB, StButtonTypeBDisabled } from '../../style/styles'

type PracticeModalProps = {
  taskInstance: TaskInstance
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function PracticeModal(props: PracticeModalProps) {
  const [comment, setComment] = useState<string>(
    props.taskInstance.workerComment || ''
  )
  const updatePracticeWorkerComment = useUpdatePracticeWorkerComment()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    updatePracticeWorkerComment.mutate({
      workerId: props.taskInstance.worker.id,
      taskId: props.taskInstance.task.id,
      comment,
    })
  }

  useEffect(() => {
    if (updatePracticeWorkerComment.isSuccess) {
      props.onSuccess()
    }
  }, [updatePracticeWorkerComment.isSuccess])

  return (
    <>
      {updatePracticeWorkerComment.isLoading ? <Loading /> : ''}
      <div className="modal">
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.closeModal}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          <h1>実践テスト</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <fieldset disabled={!props.canEditAsWorker}>
            <textarea
              rows={5}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <button type="submit" disabled={!props.canEditAsWorker} style={props.canEditAsWorker ? StButtonTypeB : StButtonTypeBDisabled}>
              スタッフ送信
            </button>
          </fieldset>
        </form>
      </div>
    </>
  )
}

export default PracticeModal
