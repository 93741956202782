import { StButtonTypeD, StDivTypeD } from '../../style/styles';

interface ToggleButtonProps {
    bodyText: string;
    buttonText: string;
    onClick: () => void;
}

function ToggleButton({ bodyText, buttonText, onClick }: ToggleButtonProps) {

    return (
        <div style={StDivTypeD}>
            <span style={{ fontWeight: 'bolder', marginRight: "5px" }}>{bodyText}</span>
            <button onClick={(e) => {
                e.preventDefault()
                onClick()
                
            }} style={StButtonTypeD}>
                {buttonText}
            </button>
        </div>
    )
}
export default ToggleButton;
