import { FormEvent, useEffect, useState } from 'react'
import { TaskInstance } from '../../types/TaskInstance'
import { useUpdateAim } from '../../queries/TasksQuery'
import moment from 'moment'
import Loading from '../Loading'
import {StButtonTypeA, StButtonTypeB, StButtonTypeC, StButtonTypeBDisabled} from '../../style/styles'

type AimSettingModalProps = {
  taskInstance: TaskInstance
  stepId: string
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function AimSettingModal(props: AimSettingModalProps) {
  const startAt = props.taskInstance.startAt ?? moment().format('YYYY-MM-DD')
  const endAt = props.taskInstance.endAt ?? moment().format('YYYY-MM-DD')

  const [aim, setAim] = useState<string>(props.taskInstance.aim || '')
  const [startAtYear, setStartAtYear] = useState<number>(
    parseInt(startAt.split('-')[0])
  )
  const [startAtMonth, setStartAtMonth] = useState<number>(
    parseInt(startAt.split('-')[1])
  )
  const [startAtDate, setStartAtDate] = useState<number>(
    parseInt(startAt.split('-')[2])
  )
  const [endAtYear, setEndAtYear] = useState<number>(
    parseInt(endAt.split('-')[0])
  )
  const [endAtMonth, setEndAtMonth] = useState<number>(
    parseInt(endAt.split('-')[1])
  )
  const [endAtDate, setEndAtDate] = useState<number>(
    parseInt(endAt.split('-')[2])
  )

  const updateAim = useUpdateAim()

  useEffect(() => {
    if (updateAim.isSuccess) {
      props.onSuccess()
    }
  }, [updateAim.isSuccess])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    updateAim.mutate({
      workerId: props.taskInstance.worker.id,
      stepId: props.stepId,
      taskId: props.taskInstance.task.id,
      aim: aim,
      startAt: new Date(
        startAtYear,
        startAtMonth - 1,
        startAtDate
      ).toDateString(),
      endAt: new Date(endAtYear, endAtMonth - 1, endAtDate).toDateString(),
    })
  }

  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )

  return (
    <>
      {updateAim.isLoading ? <Loading /> : ''}
      <div className="modal">
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.closeModal}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          <h1> {props.taskInstance.task.name} </h1>
        </div>

        <form onSubmit={handleSubmit}>
          <fieldset disabled={!props.canEditAsWorker && !props.canEditAsTrainer}>
            <div className="input date">
              <label>開始日</label>
              <select
                value={startAtYear}
                onChange={(e) => setStartAtYear(parseInt(e.target.value))}
              >
                <option value="">年</option>
                {range(2022, 2032, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
              <select
                value={startAtMonth}
                onChange={(e) => setStartAtMonth(parseInt(e.target.value))}
              >
                <option value="">月</option>
                {range(1, 12, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
              <select
                value={startAtDate}
                onChange={(e) => setStartAtDate(parseInt(e.target.value))}
              >
                <option value="">日</option>
                {range(1, 31, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="input date">
              <label>終了日</label>
              <select
                value={endAtYear}
                onChange={(e) => setEndAtYear(parseInt(e.target.value))}
              >
                <option value="">年</option>
                {range(2022, 2032, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
              <select
                value={endAtMonth}
                onChange={(e) => setEndAtMonth(parseInt(e.target.value))}
              >
                <option value="">月</option>
                {range(1, 12, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
              <select
                value={endAtDate}
                onChange={(e) => setEndAtDate(parseInt(e.target.value))}
              >
                <option value="">日</option>
                {range(1, 31, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
            </div>
          </fieldset>
            <textarea
              disabled={props.canEditAsTrainer ? !props.canEditAsTrainer : !props.canEditAsWorker}
              rows={5}
              value={aim}
              onChange={(e) => setAim(e.target.value)}
            />
            <div className="submit">
              <input type="submit" value="スタッフ送信" 
                disabled={!props.canEditAsWorker} 
                style={ (props.canEditAsWorker) ? StButtonTypeB : StButtonTypeBDisabled } 
              />
            </div>
        </form>
      </div>
    </>
  )
}

export default AimSettingModal
