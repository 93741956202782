import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetWorkerDetail } from '../queries/WorkersQuery'
import TaskIcon from '../components/TaskIcon'
import WorkerHeader from '../components/WorkerHeader'
import Loading from '../components/Loading'
import { CurriculumForWorkerDetail, StepForWorkerDetail } from '../types/WorkerDetailResponse'

function WorkerDetail() {
  // hooks
  const { workerId, brandId } = useParams()

  // states
  const [curriculumBrandName, setCurriculumBrandName] = useState<string | undefined>('')
  const [showAlarm, setShowAlarm] = useState<boolean | undefined>(false)
  const [tasksByCurriculum, setTasksByCurriculum] = useState<CurriculumForWorkerDetail[]>([]);
  const [changeSort, setChangeSort] = useState<boolean>(false);

  // queries
  const {
    isLoading,
    isFetching,
    refetch,
    data: workerDetailResponse,
  } = useGetWorkerDetail({ workerId }, { curriculumBrandName })

  // methods
  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem("scrollPosition");
    }
  };

  const handleClick = () => {
    const position = window.pageYOffset;
    sessionStorage.setItem("scrollPosition", position.toString());
  };

  const onUpdate = async () => {
    handleClick()
    await refetch().then(() => {
      handleUpdate()
      handleScrollPosition()
    });
  }

  const sortTasksByCurriculum = useCallback((sort: boolean) => {
    const order1 = sort ? -1 : 1;
    const order2 = sort ? 1 : -1;
    return tasksByCurriculum.sort((a, b) => (a.curriculumOrder < b.curriculumOrder) ? order1 : (a.curriculumOrder > b.curriculumOrder) ? order2 : 0)
  }, [tasksByCurriculum]);

  const getCurriculumsFilteredByCompletion = (curriculums: CurriculumForWorkerDetail[], isCompleted?: boolean) => {
    return curriculums.filter((curriculum) =>
      curriculum.steps.every((step) => step.taskInstances.every((taskInstance) => taskInstance.isDone)) === isCompleted
    );
  };

  const sortCurriculumsByCompletionDescending = () => {
    const curriculums = workerDetailResponse?.worker.tasksByCurriculum?.filter((curriculum) => curriculum !== null) || [];

    const curriculumsCompleted = getCurriculumsFilteredByCompletion(curriculums, true);

    const curriculumsNotCompleted = getCurriculumsFilteredByCompletion(curriculums, false)

    setTasksByCurriculum([...curriculumsNotCompleted, ...curriculumsCompleted]);
  };

  function handleUpdate() {
    if (showAlarm === true) {
      const tasksByCurriculums = workerDetailResponse?.worker.tasksByCurriculum ?? [];
      const filteredCurriculums = tasksByCurriculums.map((curriculum) => {
        const filteredSteps = curriculum.steps.filter((step) => {
          const filteredTaskInstances = step.taskInstances.filter((taskInstance) => taskInstance.isAlarm);
          return filteredTaskInstances.length > 0;
        });
        return { ...curriculum, steps: filteredSteps };
      }).filter((curriculum) => curriculum.steps.length > 0);
      setTasksByCurriculum(filteredCurriculums);
    } else {
      sortCurriculumsByCompletionDescending();
    }
  }

  // effects
  useEffect(() => {
    handleUpdate();
  }, [workerDetailResponse, showAlarm]);
  
  useEffect(() => {
    onUpdate();
  }, []);

  useEffect(() => {
    const curriculumsSorted = sortTasksByCurriculum(changeSort);

    setTasksByCurriculum(curriculumsSorted);
  }, [changeSort, tasksByCurriculum]);

  return (
    <>
      <link rel="stylesheet" href="/css/pages/worker_detail.css" />
      {isLoading || isFetching ? (
        <Loading />
      ) : (
        <>
          <WorkerHeader
            shopId={workerDetailResponse?.worker.shopId || ''}
            brandId={workerDetailResponse?.worker.brandId || ''}
            brandName={workerDetailResponse?.worker.brandName || ''}
            urlForm={workerDetailResponse?.urlForm || ''}
            workerId={workerDetailResponse?.worker.id || ''}
            workerName={workerDetailResponse?.worker.name || ''}
            growthGoal={workerDetailResponse?.worker.growthGoal || ''}
            curriculums={
              workerDetailResponse?.curriculums.filter((group) => {
                return group.items.filter((curriculum) => {
                  return !tasksByCurriculum
                    .map((item) => item.curriculumId)
                    .includes(curriculum.id)
                })
              }) || []
            }
            workerCurriculumIds={
              tasksByCurriculum.map((item) => {
                return item.curriculumId
              }) || []
            }
            onUpdate={onUpdate}
            curriculumBrandName={curriculumBrandName}
            handleCurriculumBrandName={(name: string) => setCurriculumBrandName(name)}
            hasAlarm={showAlarm}
            setHasAlarm={(alarm: boolean) => setShowAlarm(alarm)}
            sortCurriculums={(sortOrder) => setChangeSort(sortOrder)}
          />
          <div className="content">
            {workerDetailResponse && tasksByCurriculum.map(
              (curriculum) => {
                return (
                  <div key={curriculum.curriculumId}>
                    <div className="cal-title" id="task_step_111">
                      {curriculum.curriculumName}
                    </div>
                    {curriculum.steps.map((step) => {
                      return (
                        <div key={step.stepId} className="calc-content">
                          <table>
                            <tbody>
                              <tr>
                                <td>{step.stepName}</td>
                                <td>
                                  <ul className="calc-list">
                                    {step.taskInstances.map((taskInstance) => {
                                      return (
                                        <li key={taskInstance.task.id}>
                                          <TaskIcon
                                            taskInstance={taskInstance}
                                            stepId={step.stepId}
                                            stepIsDone={step.totalTasks === step.doneTasks}
                                            onUpdate={onUpdate}
                                          />
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )
                    })}
                  </div>
                )
              }
            )}
          </div>
        </>
      )}
    </>
  )
}

export default WorkerDetail
