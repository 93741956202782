import styled from 'styled-components'

export const ScPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
export const ScFooter = styled.footer`
  background: #eee;
  padding: 10px 0 200px;
  margin-top: auto;
  text-align: center;
  color: #aaa;
  font-size: 12px;
`

export const ScPageTitleTypeA = styled.h3`
  margin-bottom: 20px;
`

export const ScHeadline = styled.div`
  background: #ff0000;
  padding: 5px;
`

export const ScListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 30px auto;
  padding: 0 30px;
`

export const ScFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 30px auto;
  padding: 0 30px;
`

export const ScFormItem = styled.div`
  margin: 10px 0 30px 0;
  & > p {
    margin-bottom: 6px;
  }
`

export const ScFormButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px 10px;
`

export const ScUserListTable = styled.table`
  border-width: thin;
  border-style: solid;
  border-collapse: collapse;
  & th {
    border: solid 1px #9c9c9c;
    white-space: nowrap;
  }
  & td {
    border: solid 1px #9c9c9c;
  }
  & th,
  td {
    padding: 4px 4px;
  }
`

export const ScUserListBottomLinks = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 10px 0;
    color: blue;
    text-decoration: underline;
  }
`

export const ScButtonTypeA = styled.button`
  padding: 10px 20px;
  background: #00c9f3;
  color: #fff;
  border-radius: 10px;
  font-size: 1rem;
`

export const ScButtonTypeB = styled.button`
  padding: 10px 20px;
  background: #00c9f3;
  color: #fff;
  border-radius: 10px;
  font-size: 24px;
`

export const StButtonTypeA = {
  padding: '10px 20px',
  background: '#00c9f3',
  color: '#fff',
  borderRadius: '10px',
  fontSize: '1rem',
  cursor: 'pointer',
}

export const StButtonTypeB = {
  padding: '10px 20px',
  background: '#00c9f3',
  color: '#fff',
  borderRadius: '10px',
  fontSize: '24px',
  cursor: 'pointer',
}

export const StButtonTypeBDanger = {
  padding: '10px 20px',
  background: '#ff0000',
  color: '#fff',
  borderRadius: '10px',
  fontSize: '24px',
  cursor: 'pointer',
}

export const StButtonTypeC = {
  padding: '15px',
  borderRadius: '5px',
  color: '#fff',
  background: '#aaa',
  cursor: 'pointer',
}

export const StButtonTypeBDisabled = {
  padding: '10px 20px',
  background: '#aaa',
  color: '#fff',
  borderRadius: '10px',
  fontSize: '24px',
}

export const StButtonTypeD = {
  padding: '5px 10px',
  background: '#FF8A33',
  color: '#fff',
  borderRadius: '5px',
  fontSize: '1rem',
  cursor: 'pointer',
  width: '60px',
}

export const StDivTypeD = {
  padding: '0 10px',
  background: '#FFF28E',
  borderRadius: '5px',
  height: '46px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const StButtonTypeE = {
  background: '#fa8132',
  fontSize: '1rem',
  padding: '5px 10px',
  borderRadius: '5px',
  color: '#fff',
  cursor: 'pointer'
}

export const StButtonTypeEDisabled = {
  background: '#f5ba80',
  padding: '15px',
  borderRadius: '5px',
  color: '#fff',
  cursor: 'not-allowed'
}