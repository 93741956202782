import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useChangePassword } from "../queries/UsersQuery";
import Loading from "../components/Loading";
import { useGetAuthUser, useLogout } from "../queries/AuthQuery";
import {
  ScFormButtons,
  ScFormContainer,
  ScFormItem,
  ScPageTitleTypeA,
} from "../style/styles";

const PasswordChange: React.VFC = () => {
  const { isLoading, refetch, data: authUser } = useGetAuthUser();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isShowPassword, setIsShowPassword] = useState(0);
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);

  const navigate = useNavigate();
  const changePassword = useChangePassword();
  const logout = useLogout();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!authUser?.id) return;
    changePassword.mutate({ userId: authUser.id, password, confirmPassword});
  };

  useEffect(() => {
    if (changePassword.isSuccess) {
      refetch().then(() => {
        navigate(`/`);
      });
    }
  }, [changePassword.isSuccess]);

  useEffect(() => {
    if(confirmPassword !== password) {
        setShowConfirmPasswordError(true)
    } else {
        setShowConfirmPasswordError(false)
    }
  }, [confirmPassword]);

  return (
    <>
      {(isLoading || changePassword.isLoading || logout.isLoading) && (
        <Loading />
      )}
      <link rel="stylesheet" href="../css/pages/login.css" />
      <ScFormContainer>
        <ScPageTitleTypeA>パスワードを変更してください</ScPageTitleTypeA>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <ScFormItem>
              <p>新しいパスワード</p>
              <input
                type={ isShowPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </ScFormItem>
            <ScFormItem>
              <p>新しいパスワード（確認）</p>
              <input
                type='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
                { showConfirmPasswordError ? <div className="error-message" >パスワードが上記と一致しません</div> : '' }
            </ScFormItem>
            <ScFormItem>
              <input
                type="checkbox"
                value={isShowPassword}
                id="showPassword"
                onChange={(e) => setIsShowPassword(e.target.checked ? 1 : 0)}
              />
              <label htmlFor='showPassword'>パスワード表示する</label>
            </ScFormItem>
          </fieldset>
          <ScFormButtons>
            <input
              type="submit"
              value="パスワードを変更"
              style={{
                fontSize: "14px",
                color: "#fff",
                padding: "5px 20px",
                margin: "50px auto 0",
                borderRadius: "10px",
                background: "#00c9f3",
              }}
            />
            <button
              style={{
                fontSize: "14px",
                padding: "5px 20px",
                border: "none",
                backgroundColor: "#999",
                borderRadius: "10px",
              }}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              戻る
            </button>
            <button
              style={{
                fontSize: "14px",
                padding: "5px 20px",
                border: "none",
                backgroundColor: "#999",
                borderRadius: "10px",
              }}
              onClick={(e) => {
                e.preventDefault();
                logout.mutate();
              }}
            >
              ログアウト
            </button>
          </ScFormButtons>
        </form>
      </ScFormContainer>
    </>
  );
};

export default PasswordChange;
