import { useEffect, useState } from 'react'
import { useLogout } from '../queries/AuthQuery'
import { useModal } from 'react-hooks-use-modal'
import { ShopGrowthGoalEditModal } from './modal/ShopGrowthGoalEditModal'
import { useGetBrandsForCurriculums, useGetShopsBrands } from '../queries/ShopsQuery'
import { useGetPasswordConfig } from '../queries/UsersQuery'
import { useAuthUser } from '../hooks/AuthUserContext'
import { NavLink, useNavigate } from 'react-router-dom'
import { StButtonTypeC, StButtonTypeD, StDivTypeD, StButtonTypeE, StButtonTypeEDisabled } from '../style/styles'
import Loading from './Loading'
import { UserRole } from '../types/UserRole'
import ToggleButton from './toggleButton'
import useConfig from '../hooks/GetConfig'

type ShopHeaderProps = {
  shopId: string
  brandId: string | undefined
  shopName: string
  brandName: string
  growthGoal: string
  urlForm: string
  onUpdate: () => void
  isBulkEditing?: boolean
  onToggleBulkEditing?: () => void
  curriculumBrandName?: string
  handleCurriculumBrandName?: (name: string) => void
  hasSupporter?: boolean
  setHasSupporter?: (supporter: boolean) => void
  hasAlarm?: boolean
  setHasAlarm?: (alarm: boolean) => void
  setChangeStaffListSortState?: (sort: string) => void
  changeStaffListSortState?: string
}

function ShopHeader(props: ShopHeaderProps) {
  // states
  const [canEditAsWorker, setCanEditAsWorker] = useState<boolean>(false)
  const [canEditAsTrainer, setCanEditAsTrainer] = useState<boolean>(false)
  const [brandId, setBrandId] = useState<string | undefined>(props.brandId)
  const [isSortingStaffs, setIsSortingStaffs] = useState<boolean>(false)
  const navigate = useNavigate()

  // hooks
  const logout = useLogout()
  const { authUser } = useAuthUser()
  const { isLoading, isFetching, refetch, data: passwordConfig } = useGetPasswordConfig()
  const { data: getShopsBrandsResponse } = useGetShopsBrands(props.shopId)
  const { data: brandsForCurriculums } = useGetBrandsForCurriculums(props.shopId)
  const [Modal, open, close, isOpen] = useModal('root', { preventScroll: true, closeOnOverlayClick: true, })
  const [ModalPassword, openModalPassword, closeModalPassword, isOpenModalPassword] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  })

  // methods
  const setHasSupporter = (supporter: boolean) => { props.setHasSupporter!(supporter) }

  const handleSortStaffs = () => {
    if (!props.setChangeStaffListSortState) return
    if (props.changeStaffListSortState === 'asc') {
      props.setChangeStaffListSortState('desc')
      return
    }
    props.setChangeStaffListSortState('asc')
  }

  const onEnableSort = () => {
    if (!props.setChangeStaffListSortState) return
    if (!isSortingStaffs) {
      props.setChangeStaffListSortState('asc')
    }
    if (isSortingStaffs) {
      props.setChangeStaffListSortState('default')
    }
    setIsSortingStaffs(!isSortingStaffs)
  }

  // consts
  const config = useConfig();
  const hasRoleManagerAdmin = authUser?.role === UserRole.Manager || authUser?.role === UserRole.Admin
  const hasRoleStore1ManagerAdmin = authUser?.role === UserRole.Manager || authUser?.role === UserRole.Admin || authUser?.role === UserRole.Store1
  const hasRoleTrainerManagerAdmin = authUser?.role === UserRole.Trainer || authUser?.role === UserRole.Manager || authUser?.role === UserRole.Admin || authUser?.role === UserRole.Store1

  // effects
  useEffect(() => {
    switch (authUser?.role) {
      case UserRole.Worker:
        setCanEditAsWorker(false)
        setCanEditAsTrainer(false)
        break
      case UserRole.Trainer:
      case UserRole.Store1:
      case UserRole.Manager:
        setCanEditAsWorker(false)
        setCanEditAsTrainer(props.shopId === authUser?.shopId)
        break
      case UserRole.Admin:
        setCanEditAsWorker(true)
        setCanEditAsTrainer(true)
        break
    }
  }, [authUser])

  useEffect(() => {
    if (brandId != undefined) {
      navigate("/shop/" + props.shopId + "/worker_list/" + brandId)
    }
  }, [brandId])

  const setHasAlarm = (alarm: boolean) => {
    props.setHasAlarm!(alarm)
  }

  return (
    <>
      {logout.isLoading && <Loading />}
      <header>
        <table>
          <tbody>
            <tr>
              <td
                rowSpan={2}
                className="shop-img"
                style={{
                  backgroundImage: "url('/img/shop/shop_dummy1.png')",
                  backgroundSize: 'cover',
                }}
              />
              <td className="shop-name">{props.shopName} {props.brandName}</td>
            </tr>
            <tr>
              <td className="shop-desc" colSpan={2}>
                {authUser?.role !== UserRole.Worker ? (
                  <div className="shop-dream">
                    今月の目標
                    <p style={{ fontSize: '18px' }}>{props.growthGoal}</p>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        open()
                      }}
                    >
                      編集する
                    </a>
                    <Modal>
                      <ShopGrowthGoalEditModal
                        shopId={props.shopId}
                        growthGoal={props.growthGoal}
                        closeModal={close}
                        onSuccess={() => {
                          props.onUpdate()
                          close()
                        }}
                        canEditAsWorker={canEditAsWorker}
                        canEditAsTrainer={canEditAsTrainer}
                      />
                    </Modal>
                  </div>
                ) : (
                  ''
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </header>
      <div className="content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0 5px',
          }}
        >
          <div style={{ flexGrow: 3 }}>
            {hasRoleTrainerManagerAdmin && (
              <>
                <div style={{ display: 'inline-block' }}>
                  <h2>{config?.labels?.brand}表示</h2>
                  <select
                    className="children2"
                    value={brandId}
                    onChange={(e) => {
                      if (e.target.value != '') {
                        props.handleCurriculumBrandName?.('')
                        setBrandId(e.target.value);
                      }
                    }}
                  >
                    <option value="" className="msg">
                      -----{config?.labels?.brand}を選択-----
                    </option>
                    {getShopsBrandsResponse?.map((brand) => {
                      return (
                        <option key={brand.id} value={brand.id}>
                          {brand.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                  <h2>カリキュラム表示切替</h2>
                  <select
                    className="children2"
                    value={props.curriculumBrandName === '' ? props.brandId : props.curriculumBrandName}
                    onChange={(e) => {
                      props.handleCurriculumBrandName?.(e.target.value)
                    }}
                  >
                    <option value="" className="msg">
                      -----{config?.labels?.brand}を選択-----
                    </option>
                    {brandsForCurriculums?.map((brand) => {
                      return (
                        <option key={brand.id} value={brand.id}>
                          {brand.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </>
            )}
          </div>    
          { (config && !config?.hideFormButtons) &&

            <button
              onClick={(e) => {
                e.preventDefault()
                window.open(props.urlForm, '_blank');
              }}
              style={props.urlForm ? StButtonTypeE : StButtonTypeEDisabled}

              disabled={!props.urlForm}
            >
              フォーム
            </button>
            
          }

          {hasRoleTrainerManagerAdmin && (
            <>
              <div style={StDivTypeD}>
                <span style={{ fontWeight: 'bolder', marginRight: "5px" }}>スタッフ並び</span>
                <button onClick={onEnableSort}
                  style={StButtonTypeD}>
                  {isSortingStaffs ? 'ON' : 'OFF'}
                </button>
                <button onClick={handleSortStaffs} style={{ ...StButtonTypeD, marginLeft: '5px', opacity: isSortingStaffs ? '' : '0.5', cursor: isSortingStaffs ? 'pointer' : '' }} disabled={!isSortingStaffs}>
                  {!isSortingStaffs ? '▼' : (props.changeStaffListSortState === 'asc' ? '▼' : '▲')}
                </button>
              </div>
              <ToggleButton
                bodyText='アラームありのみ'
                buttonText={!props.hasAlarm ? 'OFF' : 'ON'}
                onClick={() => {
                  setHasAlarm(!props.hasAlarm)
                }}
              />
              <ToggleButton
                bodyText='応援者のみ表示'
                buttonText={!props.hasSupporter ? 'OFF' : 'ON'}
                onClick={() => {
                  setHasSupporter(!props.hasSupporter)
                }}
              />
            </>
          )}
          {hasRoleStore1ManagerAdmin && (
            <>
              {!props.isBulkEditing ? (
                <a
                  href="#"
                  style={StButtonTypeC}
                  onClick={(e) => {
                    e.preventDefault()
                    props.onToggleBulkEditing?.()
                  }}
                >
                  {/* {props.isBulkEditing ? 'スタッフ一覧に戻る' : 'カリキュラム一括設定'} */}
                  カリキュラム一括設定
                </a>
              ) : (
                <a
                  href="#"
                  style={StButtonTypeC}
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.reload()
                  }}
                >
                  スタッフ一覧に戻る
                </a>
              )}
            </>
          )}
          {!props.isBulkEditing && (
            <>
              {(authUser?.role !== UserRole.Worker) ? (
                <>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      openModalPassword()
                    }}
                    style={StButtonTypeC}
                  >
                    リセットパスワード表示
                  </a>
                  <ModalPassword>
                    <div className="modal">
                      <div className="modal-close">
                        <button type="button" className="modal-close-button" onClick={(e) => { closeModalPassword() }}>
                          <img src="/images/circle-xmark.svg" className="modal-close-icon" />
                        </button>
                      </div>
                      <div className="modal-header">
                        <h1>リセットパスワード表示</h1>
                      </div>

                      <div>
                        <input type='text' value={passwordConfig} className='catselect width96'></input>
                      </div>

                    </div>
                  </ModalPassword>
                </>
              ) : (
                ''
              )}
              <NavLink to={`/password_change`} style={StButtonTypeC}>
                パスワード変更
              </NavLink>
              {hasRoleManagerAdmin && (
                <NavLink to={`/shop_select`} style={StButtonTypeC}>
                  {config?.labels?.shop}選択に戻る
                </NavLink>
              )}
            </>
          )}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              logout.mutate()
            }}
            style={StButtonTypeC}
          >
            ログアウト
          </a>
        </div>
      </div>
    </>
  )
}

export default ShopHeader
