import React, { useEffect, useState } from 'react'
import { useLogout } from '../queries/AuthQuery'
import { NavLink } from 'react-router-dom'
import { useModal } from 'react-hooks-use-modal'
import CurriculumAddModal from './modal/CurriculumAddModal'
import { GrowthGoalEditModal } from './modal/GrowthGoalEditModal'
import { useAuthUser } from '../hooks/AuthUserContext'
import { UserRole } from '../types/UserRole'
import { CurriculumGroup } from '../types/CurriculumGroup'
import Loading from './Loading'
import { useGetBrandsForCurriculums } from '../queries/ShopsQuery'
import ToggleButton from './toggleButton'
import { StButtonTypeE, StButtonTypeEDisabled } from '../style/styles'
import useConfig from '../hooks/GetConfig'

type WorkerHeaderProps = {
  shopId: string
  brandId: string
  brandName: string
  urlForm: string
  workerId: string
  workerName: string
  growthGoal: string
  curriculums: CurriculumGroup[]
  workerCurriculumIds: string[]
  onUpdate: () => void
  curriculumBrandName?: string
  handleCurriculumBrandName?: (name: string) => void
  hasAlarm?: boolean
  setHasAlarm?: (alarm: boolean) => void
  sortCurriculums: (sortOrder: boolean) => void
}

function WorkerHeader(props: WorkerHeaderProps) {
  // hooks
  const { authUser } = useAuthUser()
  const logout = useLogout()
  const config = useConfig();
  const [Modal1, openModal1, closeModal1, isOpenModal1] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  })
  const [Modal2, openModal2, closeModal2, isOpenModal2] = useModal('root', {
    preventScroll: false,
    closeOnOverlayClick: true,
  })
  const { data: brandsForCurriculums } = useGetBrandsForCurriculums(props.shopId)

  // states
  const [canEditAsWorker, setCanEditAsWorker] = useState<boolean>(false)
  const [canEditAsTrainer, setCanEditAsTrainer] = useState<boolean>(false)
  const [canAddCurriculum, setCanAddCurriculum] = useState<boolean>(false)
  const [buttonText, setButtonText] = useState<string>('昇順')

  // effects
  useEffect(() => {
    switch (authUser?.role) {
      case UserRole.Worker:
        setCanEditAsWorker(props.workerId === authUser?.workerId)
        setCanEditAsTrainer(false)
        break
      case UserRole.Trainer:
      case UserRole.Store1:
        setCanEditAsWorker(props.shopId === authUser?.shopId)
        setCanEditAsTrainer(props.shopId === authUser?.shopId)
        setCanAddCurriculum(props.shopId === authUser?.shopId)
        break
      case UserRole.Manager:
        setCanEditAsWorker(props.shopId === authUser?.shopId)
        setCanEditAsTrainer(props.shopId === authUser?.shopId)
        setCanAddCurriculum(true)
        break
      case UserRole.Admin:
        setCanEditAsWorker(true)
        setCanEditAsTrainer(true)
        setCanAddCurriculum(true)
        break
    }
  }, [authUser])

  // methods
  const setHasAlarm = (alarm: boolean) => { props.setHasAlarm!(alarm) }

  return (
    <>
      {logout.isLoading && <Loading />}
      <header>
        <table>
          <tbody>
            <tr>
              <td
                rowSpan={2}
                className="shop-img"
                style={{
                  backgroundImage: "url('/img/shop/shop_dummy1.png')",
                  backgroundSize: 'cover',
                }}
              />
              <td className="shop-name">{props.workerName}</td>
            </tr>
            <tr>
              <td className="shop-desc" colSpan={2}>
                <div className="shop-dream">
                  今月の目標
                  <p style={{ fontSize: '18px' }}>{props.growthGoal}</p>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      openModal1()
                    }}
                  >
                    編集する
                  </a>
                  <Modal1>
                    <GrowthGoalEditModal
                      workerId={props.workerId}
                      growthGoal={props.growthGoal}
                      closeModal={closeModal1}
                      onSuccess={() => {
                        props.onUpdate()
                        closeModal1()
                      }}
                      canEditAsWorker={canEditAsWorker}
                      canEditAsTrainer={canEditAsTrainer}
                    />
                  </Modal1>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </header>
      <div className="content">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <NavLink
            to={`/shop/${props.shopId}/worker_list/${props.brandId}`}
            style={{
              background: '#FF7BAC',
              padding: '15px',
              borderRadius: '5px',
              color: '#fff',
            }}
          >
            <i className="fa fa-users" aria-hidden="true" />
            スタッフ別
          </NavLink>

          <div style={{ display: 'inline-block', marginLeft: '10px' }}>
            <h2>カリキュラム表示切替</h2>
            <select
              className="children2"
              value={props.curriculumBrandName === '' ? props.brandId : props.curriculumBrandName}
              onChange={(e) => {
                // setBrandName(e.target.value)
                props.handleCurriculumBrandName?.(e.target.value)
              }}
            >
              <option value="" className="msg">
                -----{config?.labels?.brand}を選択-----
              </option>
              {brandsForCurriculums?.map((brand) => {
                return (
                  <option key={brand.id} value={brand.id}>
                    {brand.name}
                  </option>
                )
              })}
            </select>
          </div>

          {(config && !config?.hideFormButtons) &&
            <button
              onClick={(e) => {
                e.preventDefault()
                window.open(props.urlForm, '_blank');
              }}
              style={props.urlForm ? StButtonTypeE : StButtonTypeEDisabled}
              disabled={!props.urlForm}
            >
              フォーム
            </button>
          }

          {authUser && authUser.role !== UserRole.Worker ? (
            <>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  openModal2()
                }}
                style={{
                  padding: '15px',
                  borderRadius: '5px',
                  color: '#fff',
                  background: '#ff0000',
                }}
              >
                ＋カリキュラム追加
              </a>
              <Modal2>
                <CurriculumAddModal
                  workerId={props.workerId}
                  curriculums={props.curriculums}
                  closeModal={closeModal2}
                  workerCurriculumIds={props.workerCurriculumIds}
                  onSuccess={() => {
                    props.onUpdate()
                    closeModal2()
                  }}
                  canEditAsWorker={canEditAsWorker}
                  canAddCurriculum={canAddCurriculum}
                />
              </Modal2>
            </>
          ) : (
            ''
          )}
          {/* TODO: hidden for release 3/30 */}
          {/* <ToggleButton
            bodyText='カリキュラム並び'
            buttonText={buttonText}
            onClick={() => {
               setButtonText(buttonText === '昇順' ? '降順' : '昇順')
               props.sortCurriculums(buttonText === '昇順' ? true : false)
            }}
          /> */}
          <ToggleButton
            bodyText='アラームありのみ'
            buttonText={!props.hasAlarm ? 'OFF' : 'ON'}
            onClick={() => {
              setHasAlarm(!props.hasAlarm)
            }}
          />
          <div>
            {!config?.secureLoginDisabled && (
              <NavLink
                to={`/password_change`}
                style={{
                  padding: '15px',
                  borderRadius: '5px',
                  color: '#fff',
                  background: '#aaa',
                }}
              >
                パスワード変更
              </NavLink>
            )}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                logout.mutate()
              }}
              style={{
                padding: '15px',
                borderRadius: '5px',
                color: '#fff',
                background: '#aaa',
                marginLeft: '10px',
              }}
            >
              ログアウト
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkerHeader
