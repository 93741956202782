import { WorkerListResponse, Worker } from '../types/WorkerListResponse'
import ReactDOMServer from 'react-dom/server'
import sanitize from 'sanitize-html'
import useConfig from '../hooks/GetConfig'

type WorkerCurriculumTableBulkAddProps = {
  workerListResponse: WorkerListResponse | undefined,
  workerCurriculums: any,
  setWorkerCurriculums: any,
  showSupports: boolean | undefined,
  workers: Worker[],
}

function WorkerCurriculumTableBulkAdd(props: WorkerCurriculumTableBulkAddProps) {
  const handleCheckboxChange = (checked: boolean, workerId: string, curriculumId: string) => {
    const newWorkerCurriculum = { ...props.workerCurriculums }

    if (checked) {
      if (newWorkerCurriculum[workerId]) {
        newWorkerCurriculum[workerId].push(curriculumId)
      } else {
        newWorkerCurriculum[workerId] = [curriculumId]
      }
    } else {
      newWorkerCurriculum[workerId] = newWorkerCurriculum[workerId].filter((item: string) => item !== curriculumId)
    }
    props.setWorkerCurriculums(newWorkerCurriculum)
  }
  const config = useConfig();

  return (
    <>
      <div className="content-staff">
        <table className="staff-list" cellSpacing={0}>
          <thead>
            <tr>
              <td id="staff-name" colSpan={3}>
                スタッフ名
              </td>
              <td id={'label-brand'}>
                {config?.labels?.brand}
              </td>
              {props.workerListResponse?.curriculums.map((curriculum) => {
                return (
                  <td key={curriculum.id} width="60" style={{ fontSize: '13px' }}>
                    {curriculum.name}
                  </td>
                )
              })}
              <td style={{ minWidth: '100px', maxWidth: '393px' }}>
                成長目標
              </td>
            </tr>
          </thead>
          <tbody>
            {props.workers.map((worker) => {
              return (
                <tr key={worker.id}>
                  <td className="staff-name" >
                    {worker.name}
                  </td>

                  <td className="staff-alarm" aria-label="アラーム">
                    {worker.alarmNum > 0 ? (
                      <>
                        <img src="/img/on.png" alt="on.png" />
                        <span className="apoint" style={{ fontSize: '13px' }} >
                          {worker.alarmNum}
                        </span>
                      </>
                    ) : (
                      <img src="/img/off.png" alt="off.png" />
                    )}
                  </td>
                  <td className="calendar-alert">
                    {worker.isCalendarAlert == true ? (
                      <img src="/img/cal-on.png" alt="cal-on.png" />
                    ) : (
                      <img src="/img/cal-off.png" alt="cal-off.png" />
                    )}
                  </td>
                  <td className="staff-supporter">
                    {worker.isSupporter === true ? worker.brandName.slice(0, 2) : ''}
                  </td>

                  {props.workerListResponse?.curriculums.map((curriculum) => {

                    const curriculumByWorker = worker.curriculums.find((item) => item.id === curriculum.id);

                    return curriculumByWorker ? (
                      <td
                        key={curriculum.id}
                        width="60"
                        style={{
                          width: '60px !important',
                          whiteSpace: 'nowrap',
                          fontSize: '13px',
                        }}
                      >
                        <span
                          style={{
                            cursor: 'pointer',
                          }}
                          data-html={true}
                          data-tip={ReactDOMServer.renderToString(
                            <div style={{ textAlign: 'left', width: 'auto' }} >
                              <table>
                                {curriculumByWorker.steps.map((step) => {
                                  <tr key={step.id} className="point-pop">
                                    <td style={{ textAlign: 'left', width: 'auto', padding: '0', backgroundColor: '#FFF' }}>{sanitize(step.name)}</td>
                                    <td style={{ textAlign: 'left', width: 'auto', padding: '0', paddingLeft: '10px', backgroundColor: '#FFF' }}>
                                      <span
                                        style={{
                                          color: '#ff0000',
                                          textAlign: 'right',
                                        }}
                                      >
                                        {step.doneTasks}/{step.totalTasks}
                                      </span>
                                    </td>
                                  </tr>
                                })}
                              </table>
                            </div>
                          )}
                        >
                          {curriculumByWorker &&
                            curriculumByWorker.doneSteps >= curriculumByWorker.totalSteps ? (
                            <img src="/img/king.png" alt="king.png" />
                          ) : (
                            curriculumByWorker && (
                              <>
                                <span
                                  style={{
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {curriculumByWorker?.doneSteps}
                                </span>
                                /{curriculumByWorker?.totalSteps}
                              </>
                            )
                          )}
                        </span>
                      </td>
                    ) : (
                      <td
                        key={curriculum.id}
                        width="60"
                        style={{
                          width: '60px !important',
                          whiteSpace: 'nowrap',
                          fontSize: '13px',
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, cursor: 'pointer' }}
                          checked={
                            props.workerCurriculums[worker.id] &&
                            props.workerCurriculums[worker.id].includes(curriculum.id)
                          }
                          onChange={(e) => {
                            handleCheckboxChange(e.target.checked, worker.id, curriculum.id)
                          }}
                          value={`worker[${worker.id}][${curriculum.id}]`}
                        />
                      </td>
                    )
                  })}
                  <td
                    style={{
                      minWidth: '240px',
                      maxWidth: '393px',
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'left',
                        height: '40px !important',
                        overflowY: 'auto',
                        transform: 'translateZ(0)',
                      }}
                    >
                      {worker.growthGoal}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default WorkerCurriculumTableBulkAdd
