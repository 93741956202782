import React, { useEffect, useState } from 'react'
import { useGetShopsBrands, useGetShopsOnly, useGetShopsAreas } from '../queries/ShopsQuery'
import { NavLink } from 'react-router-dom'
import { ScHeadline } from '../style/styles'
import Loading from '../components/Loading'
import { useAuthUser } from '../hooks/AuthUserContext'
import { GetShopsResponse, Shop, Brand } from '../types/GetShopsResponse'
import { useModal } from 'react-hooks-use-modal'
import axios from 'axios'
import { ReloadRelationCurriculumModal } from '../components/modal/ReloadRelationCurriculumModal'
import { UserRole } from '../types/UserRole'
import useConfig from '../hooks/GetConfig'

function ShopSelect() {
  const { authUser } = useAuthUser()
  const [brandId, setBrandId] = useState<string | undefined>(undefined)
  const [areaId, setAreaId] = useState<string | undefined>(undefined)
  const [shopId, setShopId] = useState<string | undefined>(undefined)
  const [shops, setShops] = useState<Shop[]>()
  const [brands, setBrands] = useState<Brand[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  })

  const { data: getAreasResponse } = useGetShopsAreas()
  const config = useConfig();
  useEffect(() => {
    const getShops = async () => {
      const { data } = await axios.get<GetShopsResponse>(`/api/shops/only/${areaId}`)
      const shops = data?.shops
      setShops([...shops])
    }

    getShops()

  }, [areaId])
  useEffect(() => {
    const getBrands = async () => {
      setIsLoading(true);
      const { data } = await axios.get<GetShopsResponse>(`/api/shops/brands/${shopId}`)
      const brands = data?.brands
      setBrands([...brands])
    }

    getBrands()
    setIsLoading(false);

  }, [shopId])

  const fetchShops = (e: { target: { value: React.SetStateAction<string | undefined> } }) => {
    setAreaId(e.target.value)
  }

  const fetchBrands = (e: { target: { value: React.SetStateAction<string | undefined> } }) => {
    setShopId(e.target.value)
  }

  return (
    <>
      {isLoading ? <Loading /> : ''}
      <link rel="stylesheet" href="/css/pages/shop_select.css" />
      <div className="container">
        <form onSubmit={(e) => e.preventDefault()}>
          <h2>{config?.labels?.area}</h2>
          <select
            className="parent"
            required
            value={areaId}
            onChange={(e) => fetchShops(e)}
          >
            <option value="" className="msg">
              -----{config?.labels?.area}を選択-----
            </option>
            {getAreasResponse?.map((area) => {
              return (
                <option key={area.id} value={area.id}>
                  {area.name}
                </option>
              )
            })}
          </select>

          <h2>{config?.labels?.shop}</h2>
          <select
            className="children"
            disabled={!areaId}
            value={shopId}
            onChange={(e) => fetchBrands(e)}
          >
            <option value="" className="msg">
              -----{config?.labels?.shop}を選択-----
            </option>
            {shops?.map((shop) => {
              return (
                <option key={shop.id} value={shop.id}>
                  {shop.name}
                </option>
              )
            })}
          </select>

          <h2>{config?.labels?.brand}</h2>
          <select
            className="children2"
            disabled={!shopId}
            value={brandId}
            onChange={(e) => setBrandId(e.target.value)}
          >
            <option value="" className="msg">
              -----{config?.labels?.brand}を選択-----
            </option>
            {brands?.map((brand) => {
              return (
                <option key={brand.id} value={brand.id}>
                  {brand.name}
                </option>
              )
            })}
          </select>

        </form>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '30px',
          }}
        >
          {authUser?.role === UserRole.Admin && (
            <NavLink to={`/users`} className="login1">
              ユーザー管理
            </NavLink>
          )}
          {brandId && (
            <NavLink to={`/shop/${shopId}/worker_list/${brandId}`} className="login2">
              店舗スタッフ一覧
            </NavLink>
          )}

          {authUser?.role === UserRole.Admin && (
            <a onClick={(e) => { e.preventDefault(); open(); }} className="updateCurriculumButton">
              カリキュラム情報更新
            </a>
          )}

          {authUser?.role === UserRole.Admin && (
            <NavLink to={`/curriculum_edit`} className="login2">
              カリキュラム追加・削除
            </NavLink>
          )}

          <Modal>
            <ReloadRelationCurriculumModal closeModal={close} onSuccess={() => { close() }} />
          </Modal>
        </div>
      </div>
    </>
  )
}

export default ShopSelect
