import React, { useEffect } from 'react'
import Loading from '../components/Loading'
import { useDeleteUser, useGetUsers } from '../queries/UsersQuery'
import { NavLink } from 'react-router-dom'
import { useLogout } from '../queries/AuthQuery'
import {
  ScListContainer,
  ScPageTitleTypeA,
  ScUserListBottomLinks,
  ScUserListTable,
} from '../style/styles'

function UserList() {
  const { isLoading, isFetching, refetch, data: users } = useGetUsers()
  const deleteUser = useDeleteUser()
  const logout = useLogout()

  const onUpdate = () => {
    refetch()
  }

  useEffect(() => {
    if (deleteUser.isSuccess) {
      onUpdate()
    }
  }, [deleteUser.isSuccess, users])

  

  return (
    <ScListContainer>
      <ScPageTitleTypeA>【 ユーザー 一覧 】</ScPageTitleTypeA>
      {deleteUser.isLoading || isLoading || isFetching ? (
        <Loading />
      ) : (
        <>
          <ScUserListTable>
            <tbody>
              <tr style={{ color: 'white', background: '#3d9be8' }}>
                <th>ログインID</th>
                <th>名前</th>
                <th>店舗</th>
                <th>ロール</th>
                <th colSpan={2}>Action</th>
              </tr>
              {users?.map((user) => {
                return (
                  <tr key={user.id}>
                    <td>{user.username}</td>
                    <td>{user.name}</td>
                    <td>{user.shop_name}</td>
                    <td>{user.role}</td>
                    <td>
                      <NavLink to={`/users/update/${user.id}`}>変更</NavLink>
                    </td>
                    <td>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                          if (window.confirm('削除してよろしいですか？')) {
                            deleteUser.mutate({ userId: user.id })
                          }
                        }}
                      >
                        削除
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </ScUserListTable>
          <ScUserListBottomLinks>
            <NavLink to={`/users/create`}>ユーザー登録</NavLink>
            <NavLink to={`/shop_select`}>戻る</NavLink>
            <a
              href={``}
              onClick={(e) => {
                e.preventDefault()
                logout.mutate()
              }}
            >
              ログアウト
            </a>
          </ScUserListBottomLinks>
        </>
      )}
    </ScListContainer>
  )
}

export default UserList
