import { NavLink } from 'react-router-dom'
import { WorkerListResponse, Worker } from '../types/WorkerListResponse'
import { AuthUser } from '../types/AuthUser'
import ReactDOMServer from 'react-dom/server'
import { Tooltip } from 'react-tooltip'
import sanitize from 'sanitize-html'
import { UserRole } from '../types/UserRole'
import useConfig from '../hooks/GetConfig'
import { useState } from 'react'
import axios from 'axios'
import ReactLoading from "react-loading";

type WorkerListTableProps = {
  workerListResponse: WorkerListResponse | undefined,
  authUser: AuthUser | undefined,
  showSupports: boolean | undefined,
  showAlarm: boolean | undefined,
  changeStaffListSortState?: string,
  workersSorted: Worker[],
}

type Step = {
  id: string,
  name: string,
  doneTasks: number,
  totalTasks: number,
}

function WorkerListTable(props: WorkerListTableProps) {
  const config = useConfig();

  const [tooltipData, setTooltipData] = useState('starting...');
  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);
  const [activeTooltip, setActiveTooltip] = useState<string>('');

  const cantAccessWorker = (worker: Worker) => {
    return props.authUser?.role === UserRole.Worker && props.authUser?.workerId !== worker.id
  }

  const getSteps = async (
    workerId: string,
    curriculumId: string
  ) => {
    let url = `/api/workers/${workerId}/curriculum/${curriculumId}/steps`

    const { data } = await axios.get(url);
    return data;
  };

  const getContent = async (workerId: string, curriculumId: string) => {
    const steps = await getSteps(workerId, curriculumId);
    return ReactDOMServer.renderToString(
      <div style={{ textAlign: 'left', width: 'auto' }} >
        <table className="staff-list">
          {steps.map((step:Step) => {
            return (
              <tr key={step.id} className="point-pop">
                <td className={'staff-list-step'}>{sanitize(step.name)}</td>
                <td className={'staff-list-step'} style={{ paddingLeft: '10px' }}>
                  <span style={{ color: '#ff0000' }}>
                    {step.doneTasks}/{step.totalTasks}
                  </span>
                </td>
              </tr>
            )
          })}
        </table>
      </div>
    )
  }

  const toogleTooltip = async (workerId: string, curriculumId: string) => {
    setTooltipData(ReactDOMServer.renderToString(<ReactLoading color="#555" type="spin" height={20} width={20} />));
    if (tooltipIsOpen && activeTooltip === `${workerId}-${curriculumId}`) {
      setTooltipIsOpen(false);
    } else {
      setActiveTooltip(`${workerId}-${curriculumId}`);
      setTooltipIsOpen(true);
      setTooltipData(await getContent(workerId, curriculumId));
    }
  }

  return (
    <>
      <div className="content-staff">
        <table className="staff-list" cellSpacing={0}>
          <thead>
            <tr>
              <td className="avoid-hover" id="staff-name" colSpan={3} >
                スタッフ名
              </td>
              <td id="label-brand">
                {config?.labels?.brand}
              </td>
              {props.workerListResponse?.curriculums.map((curriculum) => {
                return (
                  <td key={curriculum.id} width="60" style={{ fontSize: '13px' }} >
                    {curriculum.name}
                  </td>
                )
              })}
              <td>
                成長目標
              </td>
            </tr>
          </thead>
          <tbody>
            {props.workersSorted.map((worker) => {
              return (
                <tr key={worker.id}>
                  <td className={"staff-name " + (cantAccessWorker(worker) ? 'avoid-hover' : '')} >
                    {cantAccessWorker(worker) ? (
                      worker.name
                    ) : (
                      <NavLink to={`/worker_detail/${worker.id}`}>
                        {worker.name}
                      </NavLink>
                    )}
                  </td>

                  {cantAccessWorker(worker) ? (
                    <td className="staff-alarm avoid-hover" aria-label="アラーム">
                      {worker.alarmNum > 0 ? (
                        <>
                          <img src="/img/on.png" alt="on.png" />
                          <span style={{ fontSize: '13px', }} >{worker.alarmNum}</span>
                        </>
                      ) : (
                        <img src="/img/off.png" alt="off.png" />
                      )}
                    </td>
                  ) : (

                    <td className="staff-alarm" aria-label='アラーム'>
                      <NavLink to={`/worker_detail/${worker.id}`}>
                        {worker.alarmNum > 0 ? (
                          <>
                            <img src="/img/on.png" alt="on.png" />
                            <span style={{ fontSize: '13px', }} >{worker.alarmNum}</span>
                          </>
                        ) : (
                          <img src="/img/off.png" alt="off.png" />
                        )}
                      </NavLink>
                    </td>
                  )}

                  {props.authUser?.role === UserRole.Worker &&
                    props.authUser?.workerId !== worker.id ? (
                    <td className="calendar-alert">
                      {worker.isCalendarAlert === true ? (
                        <img src="/img/cal-on.png" alt="cal-on.png" />
                      ) : (
                        <img src="/img/cal-off.png" alt="cal-off.png" />
                      )}
                    </td>
                  ) : (
                    <td className="calendar-alert">
                      <NavLink to={`/worker_detail/${worker.id}`}>
                        {worker.isCalendarAlert === true ? (
                          <img src="/img/cal-on.png" alt="cal-on.png" />
                        ) : (
                          <img src="/img/cal-off.png" alt="cal-off.png" />
                        )}
                      </NavLink>
                    </td>
                  )}

                  <td className="staff-supporter">
                    {worker.isSupporter === true ? worker.brandName.slice(0, 2) : ''}
                  </td>

                  {props.workerListResponse?.curriculums.map((curriculum) => {
                    const curriculumByWorker = worker.curriculums.find((item) => item.id === curriculum.id);
                    return (
                      <td
                        key={curriculum.id}
                        width="60"
                        onClick={() => { getSteps(worker.id, curriculum.id) }}
                        style={{
                          width: '60px !important',
                          whiteSpace: 'nowrap',
                          fontSize: '13px',
                        }}
                      >
                        <span
                          style={{ cursor: 'pointer' }}
                          data-html={true}
                          data-tooltip-variant="light"
                          data-tooltip-position-strategy="fixed"
                          data-tooltip-id="tooltip-steps"
                          data-tooltip-html={tooltipData}
                          onClick={() => toogleTooltip(worker.id, curriculum.id)}
                        >
                          {curriculumByWorker &&
                            curriculumByWorker?.doneSteps >= curriculumByWorker?.totalSteps ? (
                            <img src="/img/king.png" alt="king.png" />
                          ) : (
                            curriculumByWorker && (
                              <>
                                <span style={{ fontSize: '24px', fontWeight: 'bold', }}>
                                  {curriculumByWorker?.doneSteps}
                                </span>
                                /{curriculumByWorker?.totalSteps}
                              </>
                            )
                          )}
                        </span>
                      </td>
                    )
                  })}
                  <td style={{ minWidth: '240px', maxWidth: '393px' }}>
                    <div id={'staff-goal'} >
                      {worker.growthGoal}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Tooltip id="tooltip-steps"
          style={{ zIndex: 9999 }}
          place="right"
          openOnClick={true}
          isOpen={tooltipIsOpen}
        />
      </div>
    </>
  )
}

export default WorkerListTable
