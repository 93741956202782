import * as api from "../api/TasksAPI";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const useUpdateAim = () => {
  return useMutation(api.updateAim, {
    onSuccess: () => {
      toast.success("目標設定を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "目標設定の更新に失敗しました。"
      );
    },
  });
};

const useUpdateSelfChecks = () => {
  return useMutation(api.updateSelfChecks, {
    onSuccess: () => {
      toast.success("セルフチェック項目を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "セルフチェック項目の更新に失敗しました。"
      );
    },
  });
};

const useUpdateTrainerChecks = () => {
  return useMutation(api.updateTrainerChecks, {
    onSuccess: () => {
      toast.success("トレーナーチェック項目を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "トレーナーチェック項目の更新に失敗しました。"
      );
    },
  });
};

const useMarkMovieAsWatched = () => {
  return useMutation(api.markMovieAsWatched, {
    onSuccess: () => {
      toast.success("動画視聴を完了しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "動画視聴の完了に失敗しました。"
      );
    },
  });
};

const useMarkSheetAsRead = () => {
  return useMutation(api.markSheetAsRead, {
    onSuccess: () => {
      toast.success("紙マニュアルの閲覧を完了しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "紙マニュアルの閲覧の完了に失敗しました。"
      );
    },
  });
};

const useUpdatePracticeWorkerComment = () => {
  return useMutation(api.updatePracticeWorkerComment, {
    onSuccess: () => {
      toast.success("コメントを送信しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "コメントの送信に失敗しました。"
      );
    },
  });
};

const useUpdateFeedbackWorkerComment = () => {
  return useMutation(api.updateFeedbackWorkerComment, {
    onSuccess: () => {
      toast.success("コメントを送信しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "コメントの送信に失敗しました。"
      );
    },
  });
};

const useUpdatePracticeFeedbackWorkerComment = () => {
  return useMutation(api.updatePracticeFeedbackWorkerComment, {
    onSuccess: () => {
      toast.success("コメントを送信しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "コメントの送信に失敗しました。"
      );
    },
  });
};

const useUpdateFeedbackTrainerComment = () => {
  return useMutation(api.updateFeedbackTrainerComment, {
    onSuccess: () => {
      toast.success("コメントを送信しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "コメントの送信に失敗しました。"
      );
    },
  });
};

const useUpdatePracticeFeedbackTrainerComment = () => {
  return useMutation(api.updatePracticeFeedbackTrainerComment, {
    onSuccess: () => {
      toast.success("コメントを送信しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "コメントの送信に失敗しました。"
      );
    },
  });
};

export {
  useUpdateAim,
  useUpdateSelfChecks,
  useUpdateTrainerChecks,
  useMarkMovieAsWatched,
  useMarkSheetAsRead,
  useUpdatePracticeWorkerComment,
  useUpdateFeedbackWorkerComment,
  useUpdatePracticeFeedbackWorkerComment,
  useUpdateFeedbackTrainerComment,
  useUpdatePracticeFeedbackTrainerComment,
};
